.product-document-modal-form {


  padding: 20px;


  .product-document-modal-row {
    margin-bottom: 10px;

    ul {
      padding: 0 0;
    }
  }
}
