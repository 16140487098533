.email-whitelabel-preview {
  .wl-thumbnail-container {
    margin: auto;
    width: 302px;

    .wl-thumbnail {
      border: 1px solid gray;
      height: 430px;
      position: relative;
    }
    .wl-header {
      flex-shrink: 0;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wl-logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        height: 30px;


        img {
          height: 100%;
          width: auto;
        }
      }
      .wl-headings {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: end;
        height: 100%;

        .links {
          font-size: 6px;
        }

      }
      .wl-info {
        display: flex;
        flex-direction: column;
        .telephone {
          font-size: 8px;
        }
        .hours {
          font-size: 4px;
        }
      }
      .wl-right-hand-container{
        height: 100%;
        margin: 3px;

      }
    }

    .wl-email-body {
      padding: 10px 20px;
      background-color: #fff;

      > p {
        margin: 4px 0;
      }
      .wl-email-salutation {
        font-size: 9px;
        font-weight: bold;
      }

      .wl-email-text {
        font-size: 7px;
      }



    }
    .wl-footer {
      padding: 10px;
      text-align: center;
      font-size: 5px;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}
