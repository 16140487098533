.page-transition-enter {
  opacity: 0;
  transition: .2s;

}

.page-transition-enter.page-transition-enter-active {
  opacity: 0.9;
  transition: .4s;
}

.page-transition-leave {
  opacity: 0;
  position: fixed;
}

.page-transition-leave.page-transition-leave-active {
  opacity: 0;
  position: fixed;
}

.page-container div.page-transition-leave {
  opacity: 0;
  position: fixed;
}
