@import "../../../../styles/config/config";

.login-form-container {
  width: 100%;
  height: 100%;
}

.login {
  color: $red !important;
}

.login-box {
  width: 100%;
  //margin: auto;
  margin-left: 15px;
  margin-right: 15px;

  h2 {
    color: $brand-sexternary !important;
    font-weight: 500 !important;
    font-size: 28px;
    padding: 1em 30px;
    margin: 0;
  }

  > .box {
    padding: 30px;
    margin-top: 2px;
    margin-bottom: 2px;

    .button-bar {
      margin-top: 10px;
      min-height: 0;
    }

    .btn {
      margin-left: 0 !important;

      &:last-child {
        margin-bottom: 0 !important;
      }

      @media (min-width: $screen-xs-min) {
        margin-left: 5px !important;
        margin-bottom: 0 !important;
      }
    }
  }

  @media (min-width: $screen-xs-min) {
    width: 500px;
  }
}

.head {
  background: #fff;
  //padding: 15px 0 5px 30px;
}

.invalid-error {
  background: #fff;
  padding: 15px;
  text-align: center;
  color: $red;
}

.notification-row {
  margin-top: 15px;
}

.login-buttons {
  @media (max-width: $screen-md-min) {
    float: right
  }

  button {
    margin-right: 5px;
  }
}

.login-form {
  .input-group {
    border: 0 !important;
    .icon {
      text-align: center;
      i {
        width: 16px;
      }
    }
  }
  input.form-control {
    border: 1px solid #cccccc !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
    min-height: 38px !important;
    background-color: white !important;
  }
  .input-group-addon {
    background-color: $brand-tertiary;
    border-color: $brand-tertiary;
    color: #ffffff;
    font-weight: bold;
  }
}


.login-box {
  form {
    .form-group:after {
      clear: both;
      content: " ";
      display: table;
    }
  }
}
