@import "../../../../../../../../styles/config/config";

.premium-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  .selected {
    //cursor: pointer;
    // border: 1px solid $brand-secondary;
    // border-radius: $radius-normal;

  }

  &--single-container {
    flex: 4;
    flex-direction: column;
  }

  &--premiums {
    display: flex;
    flex-direction: row;
  }


  &--quote-value-selected {
    background: $brand-secondary;
    color: white;
    padding: 5px 10px;
    text-align: center;

  }

  &--quote-value {
    cursor: pointer;
    border: 1px solid $brand-secondary;
    border-radius: $radius-normal;
    padding: 5px 10px;
    text-align: center;


  }


}

