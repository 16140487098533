@import "../../../../../../styles/config/config";

.mkt-title {
  font-size: 1em;
  //margin-bottom: 5px;
}

.marketing-ip {
  .box {
    color: $brand-primary;
    padding: 20px;
    //border: $brand-secondary 2px solid;
    //background: $brand-secondary;
    //color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    height: 100%;

    .title-ip {
      font-size: 1.1em;
    }

    p {
      font-size: 1em;
    }

    .flex-item-mktg-ip {
      padding-left: 10px;
    }

    h4 {
      padding-top: 5px;
      color: white;
    }
  }

  .title-ip {
    font-size: 1.1em;
  }

  .marketing-options-row-1-ip {
    padding-top: 10px;
    width: 100%;
  }

  .marketing-options-row-2-ip {
    padding-top: 15px;
    width: 100%;
  }

  .flex-box-mktg-items-ip {
    display: flex;
  }

  .flex-item-mktg-title-ip {
    align-items: center;
    flex: 1;

    .delivery-text-ip {
      padding-left: 11px;
    }

    .no-wrap-ip {
      white-space: nowrap;
    }
  }
}
