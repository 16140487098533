@import "../../../../styles/config/config";

.content-form {
  .control-label {
    position: relative;
    top: -3px;
    padding-top: 0;
  }

  radio-control {
    margin-left: 0;
  }

  .date-control-label {
    margin-left: 15px;
    top: -15px;
  }
}

.adjust-form {
  display: flex;
  align-items: center;

  .adjust-form-label {
    flex: 1;
    margin-left: 15px;
  }

  .adjust-form-field {
    flex: 4.2;
  }
}

.times-icon {
  cursor: pointer;
  transition: opacity 0.2s, transform 0.2s;

  &:hover {
    opacity: 0.4;
    transform: scale(1.2);
  }
}