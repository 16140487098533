@import "../../../../styles/config/config";

// Screening
.radio-group {
  margin-top: 12px;
  margin-bottom: 12px;
}

.radio-container {
  clear: both;

  &.inline {
    display: inline-block;
    margin-right: 20px;
  }

  &--disabled {
    opacity: 0.5;
    cursor: pointer;
  }

  .radio-group-circle {
    width: 22px;
    height: 22px;
    background: #ffffff;
    border-radius: 50%;
    border: 2px solid #4f4f4f;
    position: relative;
    float: left;
    z-index: 1;
    input {
      display: none;
    }
    label {
      cursor: pointer;
      width: 14px;
      height: 14px;
      position: absolute;
      background: transparent;
      border-radius: 50%;
      left: 2px;
      top: 2px;
      z-index: 5;
      &:after {
        opacity: 0;
        content: "";
        width: 14px;
        height: 14px;
        position: absolute;
        background: lighten(#4f4f4f, 20%);
        border-radius: 50%;
        &:hover::after {
          opacity: 0.3;
        }
      }
    }

    input:checked + label:after {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100);
      opacity: 1;
      z-index: -1 !important;
    }
  }

}
