@import "../../../../../../styles/config/config";

.title-with-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    max-width: 40px;
    margin-right: 15px;
  }
}
