@import '../../../styles/config/config';

.notes-list {
  border-top: 1px solid $brand-secondary !important;
  border-bottom: 1px solid $brand-secondary !important;

  .note-item {
    padding: 10px 15px;

    &:nth-last-of-type(even) {
      background: #f9f9f9;
    }

    .notes-text {
      color: $brand-secondary;
    }

    .author {
      font-weight: 600;
      font-size: 0.95em;
    }
    .reminder {
      color: $pink;
      font-weight: 600;
      font-size: 0.95em;
    }

    .date {
      color: darken($brand-quaternary, 10%);
      font-weight: 500;
    }

    .note-attachments {
      font-size: 0.8em;
    }

    .attachment-link a {
      color: $red;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
