@import "../../../../styles/config/config";

.notification-bar-announcements {
  position: absolute;
  float: right;
  left: -160px;
}

.notification-bar-reminder {
  position: absolute;
  float: right;
  left: -80px;

}

.notification-bar-notifications {
  position: absolute;
  float: right;

}

.icon-bt {
  margin-top: -10px;
  background: none;
  border: none;
  border-radius: 0px !important;
  color: white !important;
  padding: 2px 10px 2px 10px !important;
  text-align: center;
  font-size: 20px !important;
  float: right;
  height: 89px;
  width: 80px;
  z-index: 6 !important;
}

.icon-bt:hover {
  background: $black-transparent;
  border: none;
  z-index: 5;
}

.icon-bt:active {
  background: $black-transparent;
  border: none;
}

.icon-bt:focus {
  background: $black-transparent;
  border: none;
}


.notification-icon {
  color: white !important;
}

.dropdown-notifications {
  background: $light-gray-transparent;
  box-shadow: $shadow;
  position: absolute;
  margin-top: 80px;
  min-width: 500px;
  opacity: 0.96;
  text-align: left;
  left: -390px;
  z-index: 6;
  color: $dark-gray;
  padding: 25px 10px;
  border-radius: 0 0 $radius-normal $radius-normal;
}

.dropdown-announcements {
  background: $light-gray-transparent;
  box-shadow: $shadow;
  position: absolute;
  margin-top: 80px;
  min-width: 300px;
  opacity: 0.96;
  text-align: left;
  left: -300px;
  z-index: 6;
  color: $dark-gray;
  padding: 25px 10px;
  border-radius: 0 0 $radius-normal $radius-normal;
  @media(max-width: 560px) {
    left: -140px;

  }
}

.dropdown-reminders {
  background: $light-gray-transparent;
  box-shadow: $shadow;
  position: absolute;
  margin-top: 80px;
  min-width: 500px;
  opacity: 0.96;
  text-align: left;
  right: -100px;
  z-index: 6;
  color: $dark-gray;
  padding: 25px 10px;
  border-radius: 0 0 $radius-normal $radius-normal;

}

.badge {
  position: absolute;
  margin-top: -20px;

  background: $background-white;
  opacity: 0.6;
  color: $brand-secondary;
}

.close-area {
  position: fixed;
  width: 1000% !important;
  height: 1000% !important;
  margin-left: -100%;
  margin-top: -500px !important;
  z-index: -1 !important;
  background: blue;
  float: left;
  opacity: 0;
}

.close-area-mobile-menu {
  position: fixed !important;
  width: 1000% !important;
  height: 1000% !important;
  margin-left: -100%;
  margin-top: -500px !important;
  z-index: -1 !important;
  background: blue;
  float: left;
  opacity: 0;
}

