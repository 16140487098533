@mixin stripes($color) {
  background-color: $color;
  background-image: linear-gradient(45deg,rgba(0,0,0,.1) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.1) 50%,rgba(0,0,0,.1) 75%,transparent 75%,transparent);
  background-size: 3rem 3rem;
}

#env_banner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  @include stripes(#00BCD4);
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 0 16px #000000;

  > span {
    display: inline-block;
    padding: 3px;
  }

  &.env-qa {
    @include stripes(#673AB7);
  }

  &.env-uat {
    @include stripes(#FFEB3B);
    //color: #000000;
  }

  &.env-production {
    @include stripes(#FF0024);
    //color: #000000;
    animation: barberpole 1s linear infinite;
  }
}

@keyframes barberpole {
  from {
    background-position: 3rem 0;
  }
  to {
    background-position: 0 0;
  }
}
