@import "../../../../styles/config/config";

/* ---------------------------
   Policy Details Tabs
 ---------------------------*/

.policy-nav-tabs {
  padding-top: 10px;
  border-bottom: $no-border !important;
  margin-bottom: -5px       !important;
  width: 100%;
  display: inline-block;
  background-color: white;
  border-top-left-radius:  $radius-normal;
  border-top-right-radius: $radius-normal;
  border-bottom: 1px solid $light-gray;

  .nav-tabs > li {
    min-height: 5em;
    padding: 5px;
    width:   16%; // considering the total of 6 tabs.
    // border:  1px solid transparent;
    @media(max-width: $screen-md-min) {
      width: 49%;
    }
  }
}
