.whitelabel-document-preview {
  .wl-thumbnail-container {
    margin: auto;
    width: 302px;

    .wl-thumbnail {
      border: 1px solid gray;
      height: 430px;
      position: relative;
    }

    .wl-header {
      flex-shrink: 0;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wl-logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        height: 30px;


        img {
          height: 100%;
          width: auto;
        }
      }
    }

    .wl-document-body {
      padding: 10px 20px;
      background-color: #fff;
      font-size: 5px;
    }
    .wl-document-title {
      font-size: 7px;
      font-weight: bold;
      margin-bottom: 2px;
      margin-top: 2px;

    }

    .wl-document-table {
      width: 100%;

      .ul {
        border-bottom: 1px solid;
      }
    }


    .wl-document-info {
      margin-top: 4px;
    }


    .wl-footer {
      padding: 10px;
      text-align: center;
      font-size: 5px;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}
