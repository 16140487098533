@import "./config/config";

#unsupportedBrowser {
  .centered {
    padding: 30px;
    max-width: 500px;
    margin: 100px auto;
    background: #fff;
    border-radius: 15px;

    img {
      margin: 35px auto;
    }

    h2 {
     text-align: center;
     font-weight: bold;
    }

    .unsupported-browser-logo {
      min-height: 100px;
      background: #fff url('../images/brand/logo.svg') no-repeat center;
      background-size: contain;
      margin-bottom: 18px;
    }
  }
}
