@import "../../../../styles/config/config";

.RichEditor-root {
  background: $form-background;
  // box-shadow: $shadow4-inset;
  border: none;
  border-radius: 5px;
  font-family: $font-body;
  font-size: 14px;
  padding: 15px;
  margin-bottom: 30px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 180px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 14px;
  user-select: none;
  display: inline-block;

  &.block {
    display: block;
  }

  &.link-controls {
    display: block;
    background-color: rgba($brand-primary, .05);
    border-top: 1px solid #ddd;
    margin: 10px 0 -10px 0;
    padding: 10px 10px 8px 10px;
  }
}

.RichEditor-input {
  margin: 0 7px;
  padding: 3px 10px;
  border: 1px solid rgba($brand-primary, .3);
  border-radius: 5px;
  outline: none !important;
}

.RichEditor-styleButton {
  background-color: $brand-primary;
  color: #fff;
  cursor: pointer;
  margin: 2px;
  padding: 2px 8px;
  display: inline-block;
  border: none;
  border-radius: 2px;

  &:hover {
    background-color: darken($brand-primary, 10%);
  }
}

.RichEditor-activeButton,
.RichEditor-activeButton:hover {
  background-color: $brand-secondary;
}

.RichEditor-linkElement {
  color: lighten($brand-primary, 20%);
  text-decoration: underline;
}
