.scheme-group-details-field {

  &--remove {

    cursor: pointer;
    float: left;
    margin-top: 8px;

    svg {
      width: 30px;
      height: 30px;
    }

  }

}
