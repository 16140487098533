@import "variables";

// Variables
// ------------------------------

// control options
$select-input-bg: #fff;
$select-input-bg-disabled: #f9f9f9;
$select-input-border-color: #ccc;
$select-input-border-radius: $radius-normal;
$select-input-border-focus: $brand-primary; // purple
$select-input-border-width: 1px;
$select-input-height: 36px;
$select-input-internal-height: ($select-input-height - ($select-input-border-width * 2));
$select-input-placeholder: #aaa;
$select-text-color: #333;
$select-link-hover-color: $select-input-border-focus;

$select-padding-vertical: 8px;
$select-padding-horizontal: 10px;

// menu options
$select-menu-zindex: 1000;
$select-menu-max-height: 200px;

$select-option-color: lighten($select-text-color, 20%);
$select-option-bg: $select-input-bg;
$select-option-focused-color: $select-text-color;
$select-option-focused-bg: #f6f6f6; // pale blue
$select-option-selected-color: $select-text-color;
$select-option-selected-bg: $select-option-bg; // lightest blue
$select-option-disabled-color: lighten($select-text-color, 60%);

$select-noresults-color: lighten($select-text-color, 40%);

// clear "x" button
$select-clear-size: floor(calc($select-input-height / 2));
$select-clear-color: #999;
$select-clear-hover-color: #D0021B; // red
$select-clear-width: calc($select-input-internal-height / 2);

// arrow indicator
$select-arrow-color: #999;
$select-arrow-color-hover: #666;
$select-arrow-width: 5px;

// loading indicator
$select-loading-size: 16px;
$select-loading-color: $select-text-color;
$select-loading-color-bg: $select-input-border-color;

// multi-select item
$select-item-border-radius: calc($radius-normal / 2);
$select-item-gutter: 5px;
$select-item-padding-vertical: 2px;
$select-item-padding-horizontal: 5px;
$select-item-font-size: .9em;
$select-item-color: #fff; // pale blue
$select-item-bg: lighten($brand-primary, 10%);
$select-item-border-color: darken($select-item-bg, 10%);
$select-item-hover-color: darken($select-item-color, 5%); // pale blue
$select-item-hover-bg: darken($select-item-bg, 5%);
$select-item-disabled-color: #333;
$select-item-disabled-bg: #fcfcfc;
$select-item-disabled-border-color: darken($select-item-disabled-bg, 10%);

.Select > .Select-menu-outer {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: -1px 3px 4px 0px rgba(0, 0, 0, 0.1);

  .Select-menu {
    .Select-option {
      border-radius: 0 !important;
    }
  }
}

.has-error {
  .Select-control {
    border-color: #cb0015;
    color: #cb0015;
    background-color: #ffd3d8;
  }
}
