@import "../../../../styles/config/variables";

.stats-section-Conversion-Label {
  background-color: white;
  overflow: visible;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  right: 100px;
  z-index: 5;
  border-radius: 5px;
  h4 {
    padding: 10px 0 0 0px;
    font-weight: bold;
  }

  .conversion-label {
    width: 100%;
    color: $brand-secondary;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    background: none;
    padding: 0;
    span {
      font-size: 1em;
      margin-left: 5px;
    }
  }
}