@import "../../../../styles/config/config";

.template-selector {
  max-width: 600px;
  margin-bottom: -15px;
  select {
    position: relative;
    top: -7px;
  }
}
