@import "../../../../../styles/config/variables";

.edit-product-medical {
  &--add {
    min-height: $form-control-min-height;
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
  }

  &--remove {

    float: right;
    cursor: pointer;
  }
}
