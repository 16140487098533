@import "../../../../../../styles/config/variables";

.product-stats {
  background: white;
  //border: 1px solid $brand-quaternary;
  animation: fadeIn .5s ease 1;
  margin-top: 15px;
  border-radius: $radius-normal;
  box-shadow: $shadow;

  padding: 15px;

  &--container {

    margin-bottom: 15px;
    overflow: visible;
    position: relative;
    animation: fadeIn .5s ease 1;
    background: white;
    border-radius: 0 0 $radius-normal $radius-normal;
    border-top: none;
    min-height: 2px;

  }

  h3, h5 {
    text-align: left;
  }
}
