@import '../../../../common/components/SimpleModal/SimpleModal';
@import '../../../../styles/config/config';

.broker-logo-container {
  width: 100%;
  border: 1px dashed gray;
  background-color: #e8e9eb;
  height: 200px;
  display: flex;
  align-items: center;
  color: #6d6e70;
  font-weight: 300;
  img,
  h3 {
    margin: auto;
    max-width: 80%;
    max-height: 150px;
  }
}
.whitelabel-modal {
  @include modal();

  &__content {
    height: 100vh;
    width: 100%;
    padding: 0;

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    &--after-open {
      transform: none;
    }

    &--before-close {
      transform: none;
    }
  }

  &__overlay {
    background-color: white;
  }
}

.whitelabel__editor {
  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    margin: 0 16px 0;
    padding: 16px 0;
  }

  &__viewport {
    height: calc(100% - 62px);
    display: flex;
    justify-content: center;
    align-items: center;

    &__loader {
      text-align: center;
    }

    // TODO: Viewport Switching

    // &--iphone-portrait > iframe {
    //   height: calc(1334px / 2);
    //   width: calc(750px / 2);
    // }
    //
    // &--iphone-landscape > iframe {
    //   height: calc(750px / 2);
    //   width: calc(1334px / 2);
    // }
    //
    // &--phablet > iframe {
    //   width: 40rem;
    // }
    //
    // &--tablet > iframe {
    //   width: 52rem;
    // }
    //
    // &--small-desktop > iframe {
    //   width: 64rem;
    // }
    //
    // &--large-desktop {
    //   width: 100%;
    // }
  }

  &__iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-top: 1px solid black;
  }

  &__loader {
  }
}
