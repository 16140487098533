@import "../../../../../styles/config/config";


// Leisure Travel

.header-title {
  min-height: 65px !important;
}



/*-------------------------\
  MTA Endorsements
\-------------------------*/

.endorsements-mta-box {
  .public-DraftEditorPlaceholder-root {
    //left: 10px;
  }
  .DraftEditor-editorContainer {
  }
  .RichEditor-editor {
    border-top: none;
  }
  .DraftEditor-root {
    border-top: none;
    border-radius: 15px;
    background: white;
    padding-left: 15px;
    //margin-left: 10px;
  }
  //margin-bottom: 40px;
  .endorsement-row {
    border-radius: 10px;
    padding: 5px;
    background: #ededed;
    margin-bottom: 30px;

    .endorsement-form {
      padding: 25px;
      margin-top: 0;
    }
  }
}


/*-----------\
Who is Going?
\-----------*/


.flexbox-container-travellers {
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: $screen-sm-min) { // 768px
    flex-direction: column;
  }
}
.flexbox-container-travellers-buttons {
  display: flex;
  padding: 10px;
  justify-content: space-around;
  align-items: center;
  @media(max-width: $screen-sm-min) { // 768px
    flex-direction: column;
  }
}

.travellers-flexbox-item {
  font-size: 14px;
}

/*-----------------------
Subtitles
------------------------*/

.sub-title {
  color: $brand-tertiary;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
}

.placeholder-fields {
  height: 45px;
}

.adjusting {
  margin: 10px 0;
  padding: 0;
}
.adjusting2 {
  margin: 10px 0;
}


/*------------------
  QUOTES BOX STRUCTURE
 -----------------*/

.select-campaign-quote {
  margin-top: -7px;
}

.quotation-type-container {
  display: flex;
  //justify-content: space-around;
  align-items: center;
  height: 95px;
  margin-top: -15px;
  //position: relative;
  //top: -10px;
  //width: 100%;
  //z-index: 5;
  //margin-bottom: 15px;
  background: $quotation-type;
}
.quote-item-2 {
  width: 20%;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.quote-item-1 {
  //animation: fadeIn .1s ease 1;
  width: 49%;
}



.button-top-padding {
  padding-top: 15px;
}

.confirm-fields-upsell {
  .confirm-text {
    padding-bottom: 15px;
    font-weight: bold;
    font-size: 15px;
  }
}

.quotes-tabs {
  border-radius: $radius-normal;
  width: 70%;
  float: left;
  padding-top: 10px;
  padding-bottom: 0;
  border-bottom: $no-border !important;
  margin-bottom: 10px       !important;
  display: inline-block;
  background-color: white;
  border-bottom: 1px solid $light-gray;

  // The Tabs:
  .nav-tabs > li {
    min-height: 5em;
    padding: 2px;
    width: 16%; // considering the total of 6 tabs: 16%
    @media(max-width: $screen-md-min) {
      width: 48%;
    }
  }
}

.tab-content {
  background: transparent !important;
}

.calculate-quote-sidebar {
  background: $background-white;
  border-radius: $radius-normal;
  float: right;
  width: 28%;
  padding: 15px !important;
  min-height: 450px;

  .quote-title {
    float: left;
    padding: 0 20px;
    font-size: 1em;
    font-weight: 900 !important;
    margin-bottom: 10px;
  }

  .campaign-sidebar-container {
    margin-bottom: 5px;
    padding-bottom: 10px;
    //border: orange 1px solid;
    border-bottom: 1px solid $light-gray;
  }

  .quote-info {
    //float: right;
    color: $brand-secondary;
    padding: 0 20px;
    font-weight: lighter;
    font-size: 1em;
    margin-bottom: 10px;
    text-align: right;

    &.red {
      color: #cc2639;
    }

    &--pink {
      color: $pink;
    }

    &.premium {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .box {
    background-color: $pink;
    margin: 0 5% 0 5%;
    color: #ffffff;
    padding: 15px;

    .info {
      color: white;
    }

    .sidebar-summary-premium {
      text-align: right;
      padding-right: 10px;

    }
  }
}

.quote-button {
  margin: 0;
  padding: 10px;
}

.error-box{
  h4{
    color: $brand-secondary;
  }
  color: red;
  background: lighten(red, 46%);
  border: 1px solid $light-gray;
  margin: 15px;
  padding: 15px;
  border-radius: $radius-large;
}

.confirm-box{
  h4{
    color: $brand-secondary;
  }
  color: red;
  background: lighten(red, 46%);
  border: 1px solid $light-gray;
  margin: 15px;
  padding: 15px;
  border-radius: $radius-large;
}

/*------------------
  BROKER DETAILS
 -----------------*/

.broker-details-title {
  font-weight: bold;
  padding-top: 10px;
  align-self: center;
  //width: 10%;
  //float: left;
  //width: 15%;
  h4 {
    color: $brand-primary;
  }
}

.broker-details-body{
  //border: red 1px solid;
  width: 85%;
  //position: relative;
  //left: -50px; // Label and Select Position
}

.padding-broker {
  position: relative;
  width: 40%;
  //float: left;
  margin-left: 50px;
  //top: -20px;
}

.padding-user {
  position: relative;
  width: 39%;
  float: right;
  top: -25px;
}

.horizontal-label{
  position: relative;
  left: -50px;
  top: 25px;
  @media (max-width: $screen-xs-min){
    display: none;
  }
}

/*------------------
  QUOTE SIDE-BAR
 -----------------*/
.apply-button-upsell {
  display: flex;
  padding-right: 5px;
  padding-top: 10px;
  justify-content: flex-end;

}
.quote-divider {
  border-bottom: 1px solid $light-gray;
  margin: 8px 0;
  h4{
    margin-left: 15px;
  }
  h5{
    margin-left: 15px;
    padding-top: 10px;
  }
}

.quote-divider-top {
  border-top: 1px solid $light-gray;
  margin: 8px 0;
  h4{
    margin-left: 15px;
  }
  h5{
    margin-left: 15px;
    padding-top: 10px;
  }
}


.selected-cover-item{
  border-bottom: 1px solid $light-gray;
  padding-top: 5px;
  span{
    font-weight: bold;

  }
  p{
    font-weight: 200;
  }
}

.calculate-quote-sidebar {
  .bottom-bar, .commission-adjust {
    margin: 15px 0;
    padding: 0 30px;
  }
}

/*------------------
  TAB CONTENT
 -----------------*/

.quote-tabs-content {
  background: none;
  border-radius: $radius-normal;
  height: auto;
}

.padding-residence {

  padding: 0;
  .residency-label {
    float: left;
    margin-left: 0;
    margin-top: 15px;
  }
}

// disabling clicking events
.loading {
  opacity: 0.6;
  pointer-events: none;
}

// Original:

.leisure-tab-content {

  background: $background-white;
  position: relative;
  height: auto;
  overflow: visible;
  padding: 30px 40px;

  .control-label {
    font-weight: 400;
    font-size: 14px;
  }

  .quotation-declaration-container {
    display: flex;

    .quotation-declaration {
      padding-top: 12px;
      p {
        font-size: 14px !important;
      }
    }

    .agree-radio {
      margin-left: 45px;
    }
  }

  .label-no-wrap {
    overflow: hidden;
    position: absolute;
    min-width: 100px;
  }

  .pull-right {
    margin-top: 0 !important;
    margin-right: 0 !important;
  }

  h4 {
    color: $brand-secondary;
  }

  .info-doc {
    margin-bottom: 10px;

    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }

}

/*------------------
  FORMS STYLING
 -----------------*/

.form-horizontal {
  position: relative !important;

  .Select-placeholder, .Select-control {
    //max-width: 400px !important;
  }


  .Select-menu-outer {
    position: absolute;
    z-index: 5 !important;
    max-width: 400px;
  }
}

.select-data {
  margin-left: 0;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .date-label {
    flex: 1;
    flex-basis: 200px;
  }

  .date-box {
    flex: 5;
    margin-left: 4px;
    display: flex;
    align-items: center;

    .form-group {
      margin: -15px 0 0 0 !important;
    }

    .label2 {
      margin: 0 20px;
    }
  }
}

.Select-menu-outer {
  position: absolute;
  z-index: 5 !important;
  width: 100%;
}

/*------------------
GET QUOTES TAB
 -----------------*/


.quote-title-top {
  color: $brand-secondary;
  font-size: 18px;
  font-weight: bold;
  padding: 0 5px;
}

.panel-transparent {
  margin: 0;
  border: none;
  padding: 0;
  box-shadow: none;

  .panel-heading {
    background: none !important;
    padding: 0 !important;
    border: none;
  }

  .panel-body {
    margin-top: 10px !important;
    padding: 0 !important;
  }
}

/*------------------
PREMIUM ADJUST
 -----------------*/

.premium-adjust-box {
  border-radius: $radius-normal;
}

.leisure-travel {

  .group-type-heading {
    margin-top: 35px;
  }
}

.form-section {
  width: 83%;
  margin-left: 17%;
  margin-top: 20px;
  margin-bottom: 30px;
  &.top-border {
    border-top: 1px solid $brand-quinternary;
    padding-top: 45px;
  }
}
.quote-main {

  //display: flex; // This change the screen table size

  h2 {
    font-size: 2.3em;
    margin-bottom: 12px;
  }

  h1, h2, h3, h4 {
    color: $brand-tertiary;
  }
  color: $brand-tertiary;

  svg {
    max-width: 100px;
    display: block;
    margin: 15px auto;
    fill: $brand-quinternary
  }

  .form-label {
    display: block;
    margin-bottom: 15px;
  }

  .quote-sidebar {
    background: #FFFFFF;
    border-top-right-radius: $radius-normal;
    border-bottom-right-radius: $radius-normal;
    margin-left: -15px;

    h1, h2, h3, h4 {
      color: $brand-primary;
      margin-top: 10px;

    }
    color: $brand-primary;

    .referral-warnings {
      color: #f82629;
      margin-bottom: 25px;

      ul {
        padding-left: 20px;
        font-size: 1.2em;
      }
    }

    .product-logo {
      max-width: 120px;
      margin: 15px auto;
      float: right;
    }

    .selected-cover-item {
      margin-bottom: 10px;
      border-bottom: 1px solid #fff;
    }
  }

  .options-cover {
    padding-top: 17px;
    padding-bottom: 17px;

    .form-radio.radio-inline {
      position: relative;
      top: -14px;
      margin-right: 40px;
      z-index: 0;
    }

    .question {
      font-size: 1em;
      font-weight: bold;
    }
  }

  .medical-screening {
    .declared-conditions-list {
      border: orange 1px solid;
      padding-left: 15px;
      margin-bottom: 25px;
    }

    .screening-session {
      padding-bottom: 25px;
    }

    .screening-message {
      border-top: 1px solid $brand-quinternary;
      margin-top: 10px;
      margin-bottom: 30px;
      padding-top: 20px;
    }
  }

  .panel-heading {
    border-radius: 0;
    padding: 0;
    background-color: $brand-quinternary;
    position: relative;

    .item-status {
      color: $brand-secondary;
      position: absolute;
      top: 5px;
      right: 25px;
      font-size: 2.3em;
    }
  }
  .panel-title {
    border-radius: 0;
    position: relative;
    top: 2px;

    a {
      padding: 20px 30px;
    }
  }

  .panel-collapse {
    transition-duration: 0.15s !important;
    .panel-body {
      border: none !important;
    }
  }

  .header-title a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    color: $brand-tertiary;
    font-size: 20px;
    font-weight: bold;

    .item-number {
      height: 42px;
      width: 42px;
      margin-top: -6px;
      margin-right: 25px;
      border-radius: 50%;
      background-color: lighten($brand-tertiary, 30%);
      float: left;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      position: relative;
      transition: ease-in-out 0.3s;
      span {
        color: #ffffff;
        font-size: 28px;
        width: auto;
        height: auto;
        padding-top: 0;
        top: 0;
        left: 5px;
      }
    }
  }
  .panel-group {
    border-radius: 0;
    margin-left: -29px;
    margin-right: -60px;
    .panel {
      border-radius: 0;
      margin-top: 2px;
      box-shadow: none;
      &.panel-active {
        > .panel-heading {
          background-color: lighten($brand-secondary, 40%);
          > .header-title a .item-number {
            background-color: lighten($brand-secondary, 10%);
          }
        }
      }
    }
  }
  .panel-default {
    border: none;
  }
  .panel-body {
    border: none;
    padding: 15px 35px !important;
    margin: 0 !important;
    max-width: 845px;
    clear: both;
  }

  .yes-no-option {
    p {
      margin-top: 30px;
    }
  }

  .winter-sports-icon {
    &.selected {
      svg {
        fill: $brand-secondary;
      }
    }
  }

  .traveller-row {
    border-bottom: 1px solid $brand-quinternary;
    margin-bottom: 25px;

    &.traveller0 {
      border-top: 1px solid $brand-quinternary;
      padding-top: 25px;
    }

    .remove-traveller {
      font-size: 1.3em;
      cursor: pointer;
      &:hover {
        color: $brand-secondary;
      }
    }
  }

  .actions {
    .pull-left {
      margin-right: 10px;
    }
  }

  .sticky {
    z-index: 100;
    .quotes-header {
      border-radius: 0;
      background: $brand-secondary;
    }
  }

  .quotes-header {
    color: #fff;
    background: $brand-tertiary;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    text-align: center;

    > div {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
  .quoted-scheme {
    border-bottom: 1px solid $brand-tertiary;

    > div {
      text-align: center;

      &.debug {
        text-align: left;
        background: rgba($brand-tertiary, .05);
        color: rgba($brand-tertiary, .5);
        border-top: 1px solid rgba($brand-tertiary, .1);

        > span {
          padding-bottom: 12px;
        }
      }

      &.errors {
        ul {
          margin-top: 10px;
          padding-left: 15px;
        }
        text-align: left;
        background: rgba($brand-secondary, .05);
        color: rgba($brand-secondary, .5);
        border-top: 1px solid rgba($brand-secondary, .1);
      }
    }

    span {
      display: inline-block;
      margin: 16px auto 0 auto;

      &.level {
        padding-top: 6px;
        font-weight: 600;
      }

      &.info {
        padding-top: 2px;
        font-size: 1.5em;
        cursor: pointer;
      }

      &.benefit {
        padding-top: 6px;
        &.multi-line {
          padding-top: 0;
          margin-top: 13px;
        }
      }

      &.premium {
        border: 1px solid $brand-tertiary;
        border-radius: 25px;
        padding: 6px 15px 6px 15px;
        margin: 16px -15px;
        font-weight: 600;
        cursor: pointer;

        .fa {
          display: inline-block;
          margin: -5px -5px -5px 5px;
          font-size: 1.6em;
          position: relative;
          top: 3px;
          left: 3px;
        }

        &.selected {
          color: #fff;
          background: $brand-secondary;
          border-color: $brand-secondary;
        }

        &.unquoted {
          cursor: default;
          color: #cbcbcb;
          background: #f3f3f3;
          border-color: #f3f3f3;
        }
      }
    }
  }
}

.medical-modal {

  a {
    color: $brand-secondary;
    text-decoration: underline;
  }
}

/*------------------
DECLARATION TAB
 -----------------*/

.declaration-container {
  display: flex;
  align-items: center;
}

.declaration-content {
  display: inline-block;
}

.declaration-definition {
  align-items: center;
}

.declaration-question {
  vertical-align: middle;
  position: relative;
  top: 14px;
}

