.clone-dynamic-document {

  &--row {
    display: flex;
    margin-bottom: 3px;
    align-items: center;

    i {
      margin-right: 5px;
    }

    input {
      min-width: 400px
    }
  }

}
