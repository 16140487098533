@import "../../../styles/config/variables";

.box {
  background-color: #fff;
  border-radius: 0;
  padding: 30px;
  margin-bottom: 15px;
  box-shadow: $shadow4;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.min-height {
    min-height: 8em;
  }

  &.double-height {
    min-height: 23.3em;
    @media (max-width: 990px) {
      min-height: 36.3em;
    }
  }

  &.secondary {
    background-color: $brand-secondary;
    color: #fff;
  }

  &.tertiary {
    background-color: $brand-tertiary;
    color: #fff;
  }
}
