@import "./variables";

$text-color: $brand-primary;
$font-family-base: $font-body;

$border-radius-base: $radius-normal;

$btn-primary-bg: $red;
$btn-primary-border: $red;
$btn-primary-color: #ffffff;

$font-size-base: 14px;
$line-height-base: 1.428571429; // 20/14
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

$full-pad: $line-height-computed * 2;
$half-pad: $line-height-computed;

$navbar-default-bg: transparent;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-bg: transparent;
$navbar-default-color: $brand-primary;
$navbar-default-link-color: $brand-primary;
$navbar-default-link-active-color: $brand-primary;

$btn-default-color: #FFF;
$btn-default-bg: $brand-primary;
$btn-default-border: $brand-primary;
$btn-primary-bg: $brand-secondary;
$btn-primary-border: $button-primary-border;

$nav-tabs-active-link-hover-border-color: $brand-secondary;
$nav-tabs-active-link-hover-bg: $brand-secondary;
$nav-tabs-active-link-hover-color: #FFF;
$nav-link-hover-bg: transparent;

$breadcrumb-active-color: #FFF;
$breadcrumb-color: $brand-primary;

$input-group-addon-bg: $brand-quaternary;
$input-border-focus: $brand-primary;

$carousel-control-color: $brand-primary;
$carousel-indicator-border-color: $brand-primary;
$carousel-indicator-active-bg: $brand-primary;
