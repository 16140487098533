@import "../../../../styles/config/variables";

.label-campaign-details {
  font-size: 1.3em;
  padding: 5px 20px;
}

.campaign-details-form {

  h2 {
    margin-bottom: 50px;
  }
  .scheme-rates {
    background: rgba(219, 222, 229, 0.3);
    border: none;
    padding: 15px;
    margin: 0 0 15px 0;

    .insurer-logo {
      max-height: 60px;
    }

    .scheme-logo-container {
      display: flex;
      align-items: center;
      background-color: #fff;
      justify-content: center;
      padding: 7px;
    }
  }
}

.campaign-details-header {
  border-bottom: 1px solid $light-gray;
  border-radius: $radius-normal;
  background: $brand-secondary;
  margin-bottom: 20px !important;
  box-shadow: $shadow4;
  //background: white;
  padding: 10px 15px;
  text-decoration: none;
  cursor: default;
  color: white;

  h4 {
    padding: 5px 0;
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;

    @media (max-width: $screen-xs-min) {
      display: none;
    }
  }
}

.campaign-details-container {
  border: 1px solid $light-gray;
  border-radius: $radius-normal;
  padding: 15px;
}
