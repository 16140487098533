@import "../../../styles/config/config";
.note {
  margin-top: 5px;
  margin-left: 10px;
}

.premium-input-fields {
  padding-right: 0 !important;
  .input-group {
    background: $premium-adjust-background;
  }
  .input-group-addon {
    border: #FDFDFD 1px solid !important;
    background: $premium-adjust-background;
    padding: 0 10px;
    @media (max-width: 1300px) {
      padding: 0 5px;
    }
  }
  .premium-adjust-fields {
    input {
      font-size: 1.0em !important;
      height: 28px;
    }


    input::placeholder {
      color: lighten(#4f4f4f, 25%);
    }
    position: relative;
    top: -1px;
    padding: 0 !important;
  }

  .form-group {
    margin: 0;
    padding-right: 0px !important;
  }
}

.input-group-addon.monetary-value {
  background: #e2e2e2;
  font-weight: normal;
  color: #ababab;
  border: none;
  cursor: not-allowed;
}

span.monetary-value {
  width: 50%;
  text-align: right;
}
