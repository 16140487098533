@import "../../../../../../styles/config/config";

.wl-available-quotes-table {
  display: flex;

  .flex1 {
    flex: 1;
    cursor: pointer;
  }

  .flex2 {
    flex: 2;
  }

  .flex3 {
    border: transparent 1px solid;
    text-align: center !important;
    flex: 2;
    position: relative;

  }

  .flex4 {
    flex: 4;
  }


}

.wl-quote-error-box {
  padding: 5px;
  color: red;
  background: lighten(red, 46%);
  font-size: 12px;
  //margin: 0 -10px 0 -15px;

  //Overwriting li from quote-tabs
  li {
    width: 100% !important;
    margin: 0 -15px;
    padding: 0;
    height: auto !important;
    min-height: 1.0em !important;
  }
}
