@import "../../../styles/config/variables";


button.btn, a.btn {
  font-weight: lighter;
  font-size: 1.0em;
  font-family: $font-button;
  border-radius: 7px;
  min-width: 80px;
  padding: 0.6em 1.0em;
  margin-bottom: 5px;
  text-transform: capitalize;
  @media (max-width: 500px) {
    width: 100%;
  }

  &.btn-xs {
    font-size: .8em;
  }
  &.btn-sm {
    font-size: 14px;
  }
  &.pad-right {
    margin-right: 1em;
  }
  &.pad-left {
    margin-left: 1em;
  }
  &.btn-default{
    margin-bottom: 5px !important;
  }

  .btn-loading {
    position: relative;
    > .fa {
      position: absolute;
      left: 50%;
      top: 10%;
      margin-left: -0.5em;
    }
  }

  i {
    &.left {
      margin-right: 8px;
    }
    &.right {
      margin-left: 8px;
    }
  }

  input {
    display: none;
  }
}
