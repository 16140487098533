@import "../../../styles/config/config";

.postcode-list {
  position: relative;

  .form-control {
    float: left;
  }

  .search-btn-container {
    padding-left: 0;
  }

  .search-btn {
    z-index: 3;
    width: 100%;
    background: $brand-secondary;
    padding: 6px 30px 8px;
    border-color: $brand-secondary;
  }

  &.adjustWidth {
    .form-control {
      max-width: 190px;
      @media (max-width: 1480px) {
        max-width: 150px;
      }
      @media (max-width: 1350px) {
        max-width: 140px;
      }
      @media (max-width: 1280px) {
        max-width: 125px;
      }
    }
    .search-btn {
      padding: 6px 25px 8px;
      @media (max-width: 1450px) {
        padding: 6px 30px 8px;
      }
      @media (max-width: 1280px) {
        padding: 6px 20px 8px;
      }
    }
  }


  .dropdown-menu.open {
    display: block;
  }

  .dropdown-menu {

    li {
      min-height: 1.5em;
      width: 100%;
      font: $font-body;
    }
    border-radius: 0 !important;
    max-height: 600px;
    overflow-y: auto;

    li.dropdown-header {
      font-style: italic;
      font-size: 14px;
    }

    li:first-child a {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    li:last-child a {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .margin-left-corrector {
    margin-left: -5px;
  }
}
