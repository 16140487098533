@import '../../../../styles/config/config';

header.header {
  background: #ffffff;
  margin: 0;
  padding: 0;

  .logout {
    background: darkgrey;
    margin: 0;
    height: 109px;
    width: 195px;
    float: right;
    @media (max-width: $screen-md-min) {
      display: none;
    }
  }

  .logo {
    margin-top: 15px;
    width: 450px; // logo size
    float: left;

    @media (max-width: $screen-md-min) {
      height: 60px;
      width: 200px;
    }

    @media (max-width: $screen-md-min) {
      height: 60px;
      width: 200px;
    }

    @media (max-width: 500px) {
      display: none;
    }
  }

  .navbar {
    //background: url(../../../../images/brand/header.png) center no-repeat;
    //background-size: contain;
    background: $banner-background-colour;
    padding: 5px;
    border-bottom: 0;
    border-right: 0;
    margin-bottom: 0;
    z-index: 3;
    // height: 120px;
    box-shadow: $shadow;
    border-radius: 0;
  }

  .info-container {
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
  }

  .broker-support-info {
    color: white;
    font-size: 13px;
    margin-right: 108px;
  }

  .user-info {
    // display: inline-block;
    display: flex;
    margin-right: 20px;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-end;
    color: $brand-white;
    //font-size: 1.0em;
    //p { font-size: 1.2em; }
    //float: right;
    //margin-right: 45px;
    //text-align: right;
    .date {
      display: flex;
      color: $brand-white;
      padding-left: 1px;
    }
  }

  .logout-title {
    cursor: pointer;
    padding-right: 5px;
  }

  .logout-title--pl {
    padding-left: 5px;
    cursor: pointer;
  }

  .user-avatar {
    //float: right;
    display: flex;
    justify-content: flex-end;

    //margin-top: 20px;
    @media (max-width: $screen-md-min) {
      display: none;
    }
  }

  .quick-search-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
    @media (max-width: $screen-md-min) {
      display: none;
    }
  }

  .logout-bt {
    color: $brand-secondary;
    cursor: pointer;
  }

  .logout-bt-mobile {
    color: $brand-secondary;
    cursor: pointer;
    margin: 0px 0 0px 5px;
    padding: 10px;
    float: right;
  }

  .collapseHeader {
    float: right;
    width: 80%;
  }

  /* ------------------------
  BURGER MENU AND DROPDOWN
  ---------------------------*/
  .burgerMenu {
    display: none;
    float: right;
    margin: 40px;
  }

  .burgerMenuBt {
    background: none;
    border: 2px solid $brand-secondary;
    padding: 2px 5px 5px 8px;
    text-align: center;
    color: $brand-secondary;
  }

  .dropmenu {
    position: absolute;
    width: 101%;
    float: left;
    left: -3px;
    box-shadow: $shadow;
  }

  .mobile-dropdown-menu-box {
    background: $light-gray-transparent;
    margin-top: 35px;
    float: left;
    position: absolute;
  }

  .navbar-brand {
    height: auto;
    position: relative;
    z-index: 2;
    font-family: $font-body;
    padding: 10px;
    margin-bottom: 0;

    .brand-mobile {
      color: $brand-primary;
      font-size: 1em;
      font-family: $font-body;
      img {
        max-width: 40px;
      }
    }
  }
}

@media (max-width: $screen-md-min) {
  header.header {
    .search-tab,
    .user-info {
      display: none;
    }
    .burgerMenu {
      display: block;
    }
  }
}
