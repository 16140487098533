@import "../../../../styles/config/config";

.settings-button {
  width: 140px;
  height: auto;
  font-size: 14px !important;
  text-align: center;
  margin-bottom: 5px;
}

.extra-dark {
  opacity: 0.85 !important;
}

.organisation-selection {
  padding: 2px 10px;
  cursor: pointer;
  &:hover {
    color: $brand-secondary;
  }
}
