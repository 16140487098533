@import "../../../../styles/config/config";

.role-form {
  .permission-category {
    .panel-heading,
    .panel-heading:hover {
      padding: 5px 15px;
      font-size: 1.3em;
      color: #fff;
      background: $brand-primary !important;
      transition: none !important;
    }
    .panel-body {
      @media (min-width: $screen-sm-min) {
        height: 300px;
        overflow: scroll;
      }
    }

    .permission-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .help-icon {
      margin-left: 7px;
    }
  }

  .permissions-container {
    padding: 5px 10px 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
