@import "../../../../../../styles/config/config";

.wl-selected-quotes {
  margin: 0 15px;

  .scheme-for-purchase {
    background: $brand-secondary;
    color: #fff;
    border-radius: $radius-large;
    padding-top: 20px;
    padding-bottom: 10px;

    h5 {
      color: #fff;
      margin: 0;
    }

    p {
      margin: 0;
      font-size: 18px;
    }

    // overwrite the quote-tabs li styling
    li {
      width: 100%;
    }

    .premium {
      font-size: 2.8em;
      font-family: $font-button;
    }

    .ipt-rate {
      font-size: .8em;
    }

    .scheme-name {
      margin-top: 10px;
    }

    img {
      border: 2px solid #fff;
      border-radius: 50%;
    }
  }

  .selected-premiums-list {
    list-style: none;
    padding-left: 0;

    li {
      font-size: 1.1em;
      padding: 10px;
      border-bottom: 1px solid #fff;

      .selected-quote-premium {
        float: right;
        display: inline-block;
        position: relative;
        top: -4px;

        strong {
          font-size: 1.4em;
        }
      }
    }
  }

  .small-premium {
    font-weight: bold;
    font-size: 1.4em;
  }

  .selection-note {
    padding: 10px;
    font-size: .8em;
  }

  .product-logo-wrapper {
    max-width: 140px;
    margin: 0 auto;
  }

  .wordings-for-purchase {
    padding: 10px 10px 10px 0;
    margin-bottom: 10px;
    width: 140%;
    position: relative;
    overflow: visible;

    .info {
      display: inline-block;
      color: #fff;
      line-height: 30px;
      font-size: 1em;
      cursor: pointer;
      float: left;
      margin-right: 12px;

      &:hover {
        color: rgba(#fff, .7);
      }

      i {
        font-size: 1.2em;
      }
    }
  }
}
