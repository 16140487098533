@import '../../../../styles/config/config';

.superseded-documents-modal {
  padding-top: 10px;
  clear: both;
  display: table;
  width: 100%;
  min-height: 95px;


  .show-documents {
    background: lighten($brand-quinternary, 10%);
    border: 1px solid $brand-secondary;
    color: darken($brand-secondary, 20%);
    padding: 15px;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
    }
  }

  .no-documents-alert {
    background: lighten($brand-quinternary, 10%);
    border: 1px solid $brand-quinternary;
    color: darken($brand-quinternary, 20%);
    padding: 15px;
    border-radius: 4px;
  }
}
