@import '../../../../styles/config/config';

.schemes-checkbox-container {
  .react-checkbox-tree {
    border: 1px solid $brand-quinternary;
    border-radius: $radius-normal;
    padding: 10px !important;
    font-size: 14px !important;

    label:hover {
      background: transparent;
    }

    .rct-node-leaf:hover {
      background: lighten($brand-quinternary, 5);
    }

    .checkbox-toggle-container {
      .rct-text {
        padding: 10px;
      }

      //
      //&.active {
      //  background: lighten($brand-secondary, 40);
      //  border-radius: 6px 6px 0 0;
      //
      //  &:hover {
      //    background: lighten($brand-secondary, 45);
      //  }
      //}
      //
      //&:hover {
      //  background: lighten($brand-secondary, 45);
      //}

      &.insurer {
        &.active {
          background: lighten($brand-secondary, 40);
          border-radius: 6px 6px 0 0;
        }

        &.rct-node-collapsed {
          //background: lighten($brand-secondary,25);

          &:hover {
            background: lighten($brand-secondary, 40);
          }
        }

        &.rct-node-expanded {
          &.rct-node-parent {
            > .rct-text {
              background: lighten($brand-secondary, 35);
            }
          }
        }
      }

      &.active {
        &.rct-node-leaf {
          > .rct-text {
            background: lighten($status-active, 25);
          }
        }

        &.rct-node-collapsed {
          background: lighten($status-active, 25);

          &:hover {
            background: lighten($status-active, 30);
          }
        }

        &.rct-node-expanded {
          &.rct-node-parent {
            > .rct-text {
              background: lighten($status-active, 25);
            }
          }
        }
      }

      &.test {
        &.rct-node-leaf {
          > .rct-text {
            background: lighten($status-pending, 25);
          }
        }

        &.rct-node-collapsed {
          background: lighten($status-test, 40);

          &:hover {
            background: lighten($status-test, 30);
          }
        }

        &.rct-node-expanded {
          &.rct-node-parent {
            > .rct-text {
              background: lighten($status-test, 25);
            }
          }
        }
      }

      &.pending {
        &.rct-node-leaf {
          > .rct-text {
            background: lighten($status-pending, 25);
          }
        }

        &.rct-node-collapsed {
          background: lighten($status-pending, 40);

          &:hover {
            background: lighten($status-pending, 30);
          }
        }

        &.rct-node-expanded {
          &.rct-node-parent {
            > .rct-text {
              background: lighten($status-pending, 25);
            }
          }
        }
      }
    }
  }
}
