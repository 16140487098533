.job-report td:first-child{
  color:black !important;
}
.job-report th{
  color: black;
  border: solid 2px grey;
}
.job-report tr{
  border: solid 1px #fff;
}
.job-report td{
  border: solid 1px #fff;
}

.job-report table{
  border: solid 2px grey;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}
.job-report td {
  padding: 5px;
  border-bottom: solid 2px #fff;
}
.red{
  color: white;
  background-color: #C93333;
}
.green{
  color: white;
  background-color: #158315;
}

.light-green{
  color: white;
  background-color: #C7E7C1;
}
.white{
  color: black;
  background-color: white;
}

.table-data {
  padding: 8px;
}

.blue{
  background-color: #C7D8F7;
}
.light-blue{
  background-color: #CCCCFF;
}
.orange{
  background-color: #FF7F50;
}
.pink{
  background-color: #FBB9B9;
}
.yellow{
  background-color: yellow;
}

.healthTable thead {
  display: table-header-group;
}
