@import "../../../../styles/config/config";

.main-container {
  width: 100%;
  position: relative;
  padding-bottom: 80px;
}

.internal-title{
  background: $brand-secondary;
  //animation: margin-slide-in 0.8s ease-in-out 1;
  padding: 30px 0 5px 30px;
  margin-bottom: 20px;
  box-shadow: $shadow4;

  h2 {
    color: white;
    text-align: left;
    font-weight: bold;
    font-size: 2em;
    @media(max-width: 560px) {
      opacity: 0;
    }
  }
}

@keyframes margin-slide-in {
  0% {
    margin-left: -300px;
  }
  100% {
    margin-left: 0;
  }
}

.internal-section {
  height: 100%;
  padding: 15px 30px 20px 30px;
  position: relative;
  margin-top: 0 !important;
}

/*--------------------------
NOTIFICATIONS BAR - top right
---------------------------*/

.notifications-nav {
  float: right;
  color: $background-white;
  position: relative;
  top:-74px;
  left: -110px;
}

.bt-bar-responsive {
  padding: 0 15px;
}

.internal-section-button-bar {
  padding: 5px 30px 15px 30px;
}

/*---------------------------------
Invisible Bottom Bar Product Quote
---------------------------------*/
.invisible-bottom-bar {
  height: 50px;
}
