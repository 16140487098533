.page-container {
  top: 0;
}

.import-favicon {
  background: url('../images/favicon/favicon.ico');
}

.dropdown-menu {
  border-radius: 0 !important;
}

.carousel {
  min-height: 10em;
  margin-left: 20px;
  margin-right: 20px;

  .carousel-control {
    &.left {
      left: -12%;
      background: none;
    }
    &.right {
      right: -12%;
      background: none;
    }
  }

  .carousel-indicators {
    bottom: -30px;
  }
}

.panel {
  .panel-heading {
    padding: 0;
    a {
      padding: 10px 15px;
    }
  }
}

.panel-collapse {
  transition-duration: 0.15s !important;
}

.form-horizontal {

  @media (min-width: $screen-sm-min) {
    .control-label {
      text-align: left !important;
    }
  }
}

.internal-container {

  .form-horizontal {
    background: #fff;
    @media (min-width: $screen-sm-min) {
      .control-label {
        padding-top: 0;
      }
    }
  }
}

.hr {
  height: 1px;
  background: $brand-secondary;
  margin: 25px auto 50px auto;

  &.no-margin {
    margin: 0 !important;
    padding: 0 !important;
  }

  &.transparent {
    background: none;
    margin-bottom: 25px;
  }
}

.background-fade {
  background: $light-gray;
}

.sticky-inner-container {
  padding-top: 5px;
}

.panel-heading {
  cursor: pointer;
}

.image-links {
  min-height: 500px;

  .image-item {
    text-align: center;
    margin-bottom: 30px;
    min-height: 230px;

    svg,
    img {
      fill: $brand-secondary;
      max-width: 150px;

      &.double {
        max-width: 200px;
      }
    }

    label {
      display: block;
      font-size: 1.2em;
      color: $brand-primary !important;
    }

    a {
      &:hover {
        text-decoration: none;
        svg {
          fill: lighten($brand-secondary, 5%);
        }
      }
    }
  }
}

.input-group {
  position: relative !important;
  background: $light-gray !important;
  border-radius: $radius-normal !important;

  .form-control {
    display: table-cell;
    background: $light-gray !important;
    box-shadow: $shadow4-inset;
  }

  .input-group-btn {
    position: absolute !important;
    background: $light-gray !important;
    right: 0;
  }
}

.has-error {
  font-size: 12px;
  .control-label {
    font-size: 14px;
  }

  .form-control,
  .radio-container .form-radio {
    border-color: #cb0015;
    color: #cb0015;
    background-color: rgb(255, 235, 235);
  }

  .radio-label {
    color: #cb0015;
  }
}

.reminders-list {
  .reminder-date {
    width: 80px;
    text-align: left;
  }

  .reminder-action {
    width: 40px;
    text-align: right;
  }
}

.admin-area {
  margin-top: -20px;
  padding-top: 20px;
}

.admin-product-list {
  .image-links a {
    color: #fff;
    &:hover {
      label {
        color: $brand-primary;
      }
    }
  }
}

//.table {
//  thead {
//    border-bottom: 1px solid $brand-secondary;
//  }
//
//  .caption {
//    font-weight: bold;
//  }
//
//  //tbody {
//  //  td {
//  //    background-color: #f9f9f9;
//  //  }
//  //  tr:nth-of-type(even) {
//  //    td {
//  //      background-color: #efefef;
//  //    }
//  //  }
//  //}
//}

.quick-save-container {
  margin-bottom: 30px;
}

.filter-label {
  padding-left: 2px;
}

/*------------
SCHEMES LAYOUT
 ------------*/

.scheme-list {
  background: $background-white;
  border-radius: $radius-normal;
  padding: 1px;
}


.scheme-block {
  border-bottom: 2px solid $light-gray;
  background: white;
  margin: 30px;
  padding-bottom: 15px;


  h2 {
    font-weight: bold;
    color: $brand-primary;
  }

  &:last-child {
    border: none;
  }

}

