@import '../../../styles/config/config';

.form-control-static {
  width: 100%;
  min-height: 34px;
  padding: 12px;
  color: #555555;
  background: #fff none;
  border-radius: 0;
  margin-top: 3px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.alignment-info-label {
  white-space: nowrap;
  padding: 6px 12px;
  margin-top: 3px;
}

.clear-margin {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.margin-label-fix {
  .form-control-static {
    margin-top: 2px;
  }
}

.infolabel-wrapper {
  .btn {
    margin-top: 15px;
  }

  .disabled {
    background-color: #F4F5F7;
  }
}

.links-style {
  li {
    a:link {
      color: $blue;
      text-decoration: underline;
    }
    a:active {
      color: $red;
    }
    a:visited {
      color: purple;
    }
    a:focus {
      color: $outline-blue;
    }
    a:hover {
      color: $brand-sexternary;
    }
  }
  span {
    a:link {
      color: $blue;
      text-decoration: underline;
    }
    a:active {
      color: $red;
    }
    a:visited {
      color: purple;
    }
    a:focus {
      color: $outline-blue;
    }
    a:hover {
      color: $brand-sexternary;
    }
  }
}
