@import "../../../../styles/config/variables";

input.quick-search {
  border-radius: 0 !important;
  font-size: 1.0em;
  margin-bottom: 1em;
  height: 30px;
  width: 450px; //search bar size
  background: $light-gray;
  //border: none;
}

.quick-search-button {
  display: none;
  //position: absolute;
  right: 7px;
  top: 5px;
  width: 44px;
  height: 42px;
}

.quick-search-container {

  .search-results-panel {
    background-color: rgba(#fff, .99);
    padding: 15px 15px 15px 15px;
    border-radius: 4px;
    border: 1px solid $light-gray;
    position: absolute;
    top: 100px;
    z-index: 18;
    width: 80%;
    box-shadow: $shadow;


    .results-list {
      list-style: none;
      margin: 0;
      padding-left: 0;
      max-height: 400px;
      overflow: scroll;

      a {
        display: block;
        padding: 8px 15px;
        font-size: 1.1em;
        background-color: rgba($brand-primary, .05);

        &:nth-of-type(even) {
          background-color: rgba($brand-primary, .1);
        }

        &:hover {
          background-color: rgba($brand-primary, .2);
        }
      }
    }
  }
}

.icon-search{
  background: $light-gray !important;
  color: $brand-secondary !important;
  border: 2px solid $light-gray !important;
}
