@import '../../../../styles/config/config';

.faqs {
  &--question-section {
    border-bottom: 1px solid $brand-primary;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  &--question-section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }

  &--delete {
    float: left;
    margin-right: 10px;
  }

  &--section-title {
    font-size: 23px;
    color: $brand-secondary;
    justify-content: center;
    flex-grow: 1;
    display: flex;
    align-items: center;

  }

  &--question-field {
    font-size: 16px;
    flex-grow: 1;
    display: flex;
    margin-left: 2px;
  }

  &--question-section-row {
    display: flex;
  }

  &--sub-section {
    border: 1px darkgrey solid;
    border-radius: 4px;
    padding: 20px;
    //background: #efefef;

    .RichEditor-root {
      background-color: white;
    }
  }

  &--question-and-answer-section {
    border: 1px darkgrey solid;
    border-radius: 4px;
    padding: 20px;
    background: #efefef;
    margin-bottom: 20px;
  }
}
