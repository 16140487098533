@import "variables";

.sortable-list {
  .list {
    list-style: none;
  }

  .overlay-wrapper {
    position: relative;
  }

  .dragging {
    .overlay {
      position: absolute;
      background: transparent;
      width: 100%;
      height: 100%;
    }

    input {
      pointer-events: none;
      tab-index: -1;
    }
  }

  .SortableItem-dragging {
    .list-item {
      position: relative;
      display: block;

      > div {
        position: relative;
        display: block;
        background: lighten($brand-tertiary, 50%) !important;
      }
    }
  }
}

