@import "../../../styles/config/variables";

.breadcrumb-box {
  margin-top: -10px;
  padding-left: 30px !important;
  margin-bottom: 55px;
}

ul.breadcrumb {
  background-color: transparent;
  padding: 0 0;
  font-size: 1.2em;
  float: left !important;
  list-style: none;
  z-index: -1;
  overflow: hidden;

  li {
    position: relative;
    z-index: 1;

    a {
      color: $pink;
      font-size: 0.8em;
      font-weight: $font-weight-regular;
      text-decoration: none;
      padding: 5px 12px 5px 25px;
      background: $background-white; /* fallback color */
      z-index: -1;
      display: inline-block;
    }
    + li:before {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 16px solid transparent; /* Go big on the size, and let overflow hide */
      border-bottom: 18px solid transparent;
      border-left: 18px solid #f2f2f2;
      top: 73%;
      margin-top: -22px;
      margin-left: 0;
      z-index: 1;
      position: absolute;
    }
    + li:after {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 13px solid transparent; /* Go big on the size, and let overflow hide */
      border-bottom: 14px solid transparent;
      border-left: 16px solid $background-white;
      top: 73%;
      margin-top: -19px;
      z-index: 2;
      position: absolute;
    }
    + li:last-child {
      background: white;
      padding-right: 15px;
      .last-child-padding{
        a {
          color: #6d6e70 !important;
          display: inline-block;
        }
      }
    }

    &.empty {
      visibility: hidden;
      width: 0;
    }
  }
}

@keyframes blink {50% { color: transparent }}
.loader-dots {
  font-size: 8px;
  margin-left: 5px;
  .loader-dot {
    animation: 1s blink infinite;
    margin-right: 4px;
  }
  .loader-dot:nth-child(2) { animation-delay: 250ms; }
  .loader-dot:nth-child(3) { animation-delay: 500ms; }
}



