@import "../../../styles/config/config";

.upload-list {
  //background: red;
  list-style: none;
  padding-left: 0;
  position: relative;

  .upload-progress {
    position: absolute;
    z-index: 0;
    background: $brand-secondary;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: $border-radius-base;
    transition: .5s width linear;
  }

  li {
    margin-bottom: 5px;
    border-radius: $border-radius-base;
    position: relative;
    background: $brand-primary;
    color: #fff;
    padding: 15px 15px 7px 40px;
    overflow: hidden;

    a {
      cursor: pointer;
      top: 10px;
      left: 15px;
      position: absolute;
      font-size: 2rem;
      margin-right: 15px;
      color: #fff;
    }

    div.content {
      position: relative;
      z-index: 1;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  /*&:after {
      content: " ";
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0.4;
      background: blue;
  }*/
}

.upload-area {
  cursor: pointer;
  border-top: 1px solid $brand-secondary;
  margin: 15px -15px -15px;
  padding: 30px 15px 20px;
  text-align: center;
  font-size: 2rem;

  + ul {
    margin-bottom: -15px;
  }

  p {
    transition: 0.3s opacity linear;
  }

  &.disabled {
    cursor: not-allowed;

    p {
      opacity: 0.1;
    }
  }

  &.droppable {
    background: darken(#ffffff, 20%);
  }

  &.active {
    background: darken($brand-quaternary, 20%);
    color: #fff;
  }
}
