@import "../../../../../../styles/config/variables";

.max_trip_durations_field_array {

  &--row {
    display: flex;
  }

  &--input {
    display: flex;

  }

  &--add {
    min-height: $form-control-min-height;
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
  }

  &--remove {

    cursor: pointer;
  }
}
