@import "../../../../../../styles/config/config";

.mta-adjustment-form {
  display: flex;
  width: 100%;
  justify-content: end;


  &--container {
    width: 70%;
    margin-bottom: 30px;
    justify-content: end;
    min-height: 180px;
    background: $brand-secondary;
    color: #fff;
    border-radius: $radius-large;
    padding: 20px 20px 10px;
    text-align: right;

    h4 {
      color: #fff;
      margin: 0;
      font-weight: bold;
    }
  }

  &--premium {
    font-size: 2.8em;
    font-family: $font-button;
  }

  &--scheme-name {
    margin-top: 10px;
  }

  &--override-button {
    margin-right: 5px;
  }

  .ipt-rate {
    font-size: .8rem;
  }


  .block-premium {
    display: flex;
    justify-content: flex-end;
  }


  &--override-input {
    min-width: 90px;
    max-width: 120px;
    height: 38px;
    margin-right: 5px;
  }

  &--override-row {
    display: flex;
  }


  .scheme-for-purchase {
    min-height: 180px;
    background: $brand-secondary;
    color: #fff;
    border-radius: $radius-large;
    padding: 20px 20px 10px;
    text-align: right;
    margin-left: -15px;
    margin-right: -15px;

    h4 {
      color: #fff;
      margin: 0;
      font-weight: bold;
    }

    p {
      margin: 0;
    }

    .premium {
      font-size: 2.8em;
      font-family: $font-button;
    }

    .ipt-rate {
      font-size: .8em;
    }

    .scheme-name {
      margin-top: 10px;
    }

    img {
      border: 2px solid #fff;
      border-radius: 50%;
    }
  }
}
