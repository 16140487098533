@import "../../../../../styles/config/config";

.reminders-list-popup {
  border-top: 1px solid $brand-secondary;
  border-bottom: 1px solid $brand-secondary;

  .text-secondary {
    color: $brand-secondary;
    font-weight: bold;
  }

  .reminder-action {
    font-size: 13px;
    width: 30px;
  }
}

.reminder-date-column {
  font-size: 13px;
  text-align: left;
}
