@import "../../../styles/config/config";

.broker-application-container {
  margin-top: 45px;
}

.broker-activation-container {
  padding: 30px;
}

.broker-application-tab {
  display: flex;
}

p.pad {
  padding-top: 10px;
  padding-bottom: 20px;
}

.adjusted-bottom-margin {
  margin-bottom: -100px;
  padding-top: 30px;
  padding-bottom: 50px;
  .corner-inverted.bottom-left {
    bottom: -78px !important;
  }
}

.adjusted-top-margin {
  margin-top: -100px;
  padding-top: 150px;
}

.adjusted-bottom-top {
  padding-top: 200px;
}

.simple-steps {
  margin-top: 50px;
}

.activation-center {
  margin-top: 75px;
}

p.form-text {
  padding: 20px 30px 15px;
  font-style: italic;
}

.broker-application {
  .panel-heading {
    border-radius: 0;
    padding: 20px 30px;
  }
  .panel-title {
    border-radius: 0;
  }
  .header-title a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    color: $brand-tertiary;
    font-size: 20px;
    font-weight: bold;

    .item-number {
      height: 42px;
      width: 42px;
      margin-top: -6px;
      margin-right: 25px;
      border-radius: 50%;
      background-color: lighten($brand-tertiary, 30%);
      float: left;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      position: relative;
      transition: ease-in-out 0.3s;
      span {
        color: #ffffff;
        font-size: 28px;
        width: auto;
        height: auto;
        padding-top: 0;
        top: 0;
        left: 5px;
      }
    }
  }
  .panel-group {
    border-radius: 0;
    .panel {
      border-radius: 0;
      margin-top: 2px;
      box-shadow: none;
      &.panel-active {
        > .panel-heading {
          background-color: lighten($brand-secondary, 40%);
          > .header-title a .item-number {
            background-color: lighten($brand-secondary, 10%);
          }
        }
      }
    }
  }
  .panel-default {
    border-color: $brand-quinternary;
  }
  .panel-body {
    background-color: $brand-quinternary;
    border-color: $brand-quinternary;
    padding: 20px 100px;
  }
}

.tab-content {
  padding: 0;
  min-height: 0;
}

.right-sidebar {
  background-color: $brand-tertiary;
  color: #fff;
  display: table-cell;
  height: 100%;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  .sticky-inner-container {
    background: white;
    margin-top: 125px;
    h4 {
      color: #fff;
    }
  }
  .logo {
    width: 35%;
    margin-top: 25px;
    margin-right: 10px;
  }
}

.no-gutter {
  padding: 0;
}

.btn-action {
  background-color: $brand-secondary;
  min-width: 100px;
  border-color: $brand-secondary;
  font-size: 12px;
  ::after {
    font-size: 0.95em;
    content: " \25B6";
  }
}

.btn-save{
  background-color: $brand-secondary;
  min-width: 100px;
  border-color: $brand-secondary;
  font-size: 12px;
  ::after {
    font-size: 0.95em;
    content: " \f08b";
  }
}



/****************\
Travelers Buttons
\****************/

//TODO: move this styling to LeisureTravel.scss

.btn-action-render {
  padding: 5px;
  margin-left: 10px;
  background: $unchecked-button;
  border-color: white;
  color: white;
  float: left !important;
  position: relative;

  &:hover {
    background-color: lighten($checked-button, 12%);
    border-color: lighten(darkgrey, 10%);
  }
  &.buttonSelected {
    background: $checked-button;
    border: darkgrey;
  }

  // validate error
  &.buttonError {
    border: $error-color-border-button 1px solid;
  }

  &.buttonSexternary {
    background: $brand-sexternary;
  }
}

.btn-action-back {
  background-color: $brand-tertiary;
  border-color: $brand-tertiary;
  float: left !important;
  position: relative;

  &:hover {
    background-color: darken($brand-tertiary, 8%);
    border-color: darken($brand-tertiary, 12%);
  }

  color: #fff;
  font-size: 12px;
  ::before {
    font-size: 0.95em;
    content: " \25c0";
  }
}

.btn-submit {
  background-color: $brand-secondary;
  border-color: $brand-secondary;
  font-size: 12px;
  .span-submit::after {
    font-size: 0.95em;
    content: " \25B6";
  }
}

.control-label {
  margin-top: 10px;
}

.info-button {
  height: 28px;
  width: 28px;
  margin-top: 40px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: $brand-tertiary;
  float: left;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  position: relative;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  span {
    color: #ffffff;
    font-size: 20px;
    width: auto;
    height: auto;
    padding-top: 0;
    top: 0;
    left: 5px;
  }
  &:hover {
    background-color: lighten($brand-tertiary, 10%);
  }
}

.add-button {
  height: 28px;
  width: 28px;
  margin-top: 5px;
  margin-right: -5px;
  border-radius: 50%;
  background-color: #ffffff;
  float: left;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  position: relative;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  span {
    color: $brand-tertiary;
    font-size: 20px;
    width: auto;
    height: auto;
    padding-top: 0;
    top: 0;
    left: 5px;
  }
  &:hover {
    background-color: darken(#ffffff, 10%);
  }
}

.partner-container {
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: 15px;
}

.input-group-btn {
  outline: none;
  .search-btn {
    font-size: 14px !important;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    height: 34px;
    background-color: $brand-secondary;
    border-color: $brand-secondary;
    content: 'Search';
  }
}

.input-group-addon {
  background-color: $brand-tertiary;
  border-color: $brand-tertiary;
  color: #ffffff;
  font-weight: bold;
}

.sub-form .row {
  margin-top: 5px;
}

.form-group {
  .error {
    margin-top: 5px;
  }
}
