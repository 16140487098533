@import "../../../../../styles/config/config";

.notifications-list-popup {
  border-top: 1px solid $brand-secondary;
  border-bottom: 1px solid $brand-secondary;

  .text-secondary {
    color: $brand-secondary;
    font-weight: bold;
  }
}
