.dropdown {
  //float: right;
  clear: both;
}

.document-version-dropdown-btn {
  padding: 5px;
  //width: 20px !important;
  min-width: auto !important;
  float: right;

  &.active {
    background: #5cb85c;

    &:focus {
      background: #5cb85c;
    }
  }

  &.pending {
    background: rgb(104, 185, 193);

    &:focus {
      background: rgb(104, 185, 193);
    }
  }

  &.ended {
    background: rgb(212, 71, 69);

    &:focus {
      background: rgb(212, 71, 69);
    }
  }
}

