@import "../../../../styles/config/config";

.signpost {
  background-color: $form-background;
  margin-top: 10px;
  margin-bottom: 10px;
  clear: both;
  padding: 10px;
  border: 1px solid $brand-primary !important;
}
