@import "../../../styles/config/config";

table {
  thead {
    tr {
      th {
        color: $table-titles;
        font-size: 1.1em;
        border-bottom: 1px solid $table-line !important;
      }
    }
  }
  tbody {
    tr {
      td {
        border-top: 0 !important;
      }
    }
  }
}

.react-bs-container-body {
  min-height: 300px;
}

.select-enabled {
  .react-bs-container-body {
    table.table tr td {
      cursor: pointer;
    }
    table.table tr:hover td {
      background-color: rgba($brand-primary, .1);
    }
  }
}

.react-bs-table-container .react-bs-table:last-of-type {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid $table-line;
  margin: 0 0 1em;
}

.react-bs-container-body > table {
  td.react-bs-table-no-data {
    vertical-align: middle;
  }
}

.bs-table-placeholder {
  margin-top: auto;
}

.react-bs-table {
  border-bottom: 1px solid $table-line;
}

.widget .react-bs-table-container .react-bs-table:last-of-type {
  min-height: 220px;
}

.react-bs-table-container table {
  margin-bottom: 0;
}

.react-bs-table-pagination {
  .pagination {
    float: right;
    margin: 0;

    > li > a,
    > li > span {
      padding: 9px 12px;
      border-radius: 0;
    }

    > li.active:first-child > a,
    > li.active:last-child > a {
      border-radius: 0 !important;
    }
  }
}
