@import "../../../styles/config/config";
.simple-radio-container {
  clear: both;

  &--disabled {
    opacity: 0.5;
    cursor: pointer;
  }

  // main checkboxes styling
  .form-radio {
    width: 20px;
    height: 20px;
    background: #ffffff;
    margin-bottom: 5px;
    border: 2px solid $brand-tertiary;
    position: relative;
    &.checkbox-disabled {
      border: darkgrey 1px solid;
      cursor: not-allowed !important;
    }
    //used with this.props.positionTop
    &.positionTop { top: 12px; }
    border-radius: $radius-small;
    float: left;
    z-index: 1;

    &.positionMid { top: 16px; }
    input {
      display: none;
    }

    &:after {
      position: absolute;
      top: 13%;
      left: 100%;
      margin-left: 0;
      font-weight: 600;
      font-size: 16px;
    }

    label {
      cursor: pointer;
      &.cursor-disabled {
        cursor: not-allowed;
      }
      width: 20px;
      height: 20px;
      background: transparent;
      z-index: 5;

      &:after {
        opacity: 0;
        content: "\2713";
        color: white;
        text-align: center;
        width: 20px;
        font-size: larger;
        height: 20px;
        position: absolute;
        left: -2px;
        top: -2px;
        border-radius: $radius-small;
        background: $brand-secondary;
        z-index: 5;

        &:hover::after {
          opacity: 0.3;
        }
      }
    }

    input:checked + label:after {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100);
      opacity: 1;
      z-index: -1 !important;
    }
  }

  .radio-label {
    font-weight: 300;
    padding-left: 30px;
    margin-top: 0;
    cursor: pointer;
    &.radio-label-condition-question {
      padding-left: 15px;
      margin-right: 10px;
    }
  }
}
