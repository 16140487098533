@import "../../../../styles/config/config";

.centered-section {
  margin-top: -60px;
  padding-top: 120px;

  img {
    padding: 30px;
    margin: 0 auto;
  }

  &.primary {
    color: #fff;
    background: $brand-primary;
    border-top-left-radius: 70px;
    border-bottom-right-radius: 70px;

    h1, h2, h3, h4, h5, h6 {
      color: #fff;
    }

    &.op-50 {
      background: rgba($brand-primary, .5);
    }

    &.op-75 {
      background: rgba($brand-primary, .75);
    }
  }

  &.tertiary {
    @extend .primary;
    background: $brand-tertiary;
  }

  &.quaternary {
    @extend .primary;
    background: $brand-quaternary;
  }

  &.white {
    @extend .primary;
    color: $brand-primary;
    background: #fff;

    h1, h2, h3, h4, h5, h6 {
      color: $brand-primary;
    }
  }

  &.secondary {
    @extend .primary;
    background: $brand-secondary;
  }

  &.no-bottom-right-radius {
    border-bottom-right-radius: 0;
  }

  &.adjusted-bottom-margin {
    z-index: 1;
    padding-bottom: 130px;

    .corner-inverted.bottom-left {
      bottom: -80px;
      z-index: 5;
    }
  }
}

.large-number {
  background-color: #FFF;
  color: $brand-secondary;
  font-family: $font-button;
  font-size: 3em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  width: 1.5em;
  height: 1.5em;
  border-radius: 1em;
}

.product-images {
  > div {
    min-height: 250px;
    text-align: center;

    h1, h2, h3 {
      color: #fff;
    }

    img {
      max-height: 130px;
      margin: 10px auto;
    }
  }
}

.partners {
  > div {
    margin-bottom: 40px;
    min-height: 200px;
    text-align: center;

    img {
      max-height: 130px;
      margin: 10px auto 25px auto;
      padding: 0;
    }
  }
}

.white {
  color: #fff;
}

.centered-section {
  .contact-details {
    img {
      margin: 0;
      padding: 0;
    }

    a {
      color: #fff;
    }
  }
}
