@import '../../../../styles/config/variables';

.scheme-management-section-container {

  .scheme-management-section-container-section {
    padding: 0;
    overflow: visible;
    background: white;
    margin-bottom: 15px;
    transition: 0.3s ease;
    animation: fadeIn 0.5s ease 1;
    border-radius: $radius-normal;
    border: 1px solid $brand-quaternary;
    //
    //    .remove-option-button {
    //      margin: 0 !important;
    //    }
    //
    //    .section-item {
    //      padding: 15px;
    //      font-size: 13px;
    //
    //      textarea.form-control {
    //        min-height: 80px;
    //      }
    //
    //      strong {
    //        color: $brand-sexternary;
    //      }
    //
    //    }
    //    .clickable-section-item {
    //      cursor: pointer;
    //    }
    //
    //    .scheme-chart-container {
    //      padding: 15px;
    //    }
    //
    //    .rates-table {
    //      height: 100%;
    //      padding: 15px;
    //      margin-top: 20px;
    //      position: relative;
    //    }
    //
    &.join {
      min-height: 2px;
      border-top: none;
      border-radius: 0 0 $radius-normal $radius-normal;
    }

    //
    //    &.no-margin {
    //      margin: 0 !important;
    //    }
  }

  //
  //  /********************************
  //    INSURER: SHOW VIEW/EDIT EFFECT
  //   ********************************/
  //
  //  .section-collapsed {
  //    opacity: 0.8;
  //    cursor: pointer;
  //    box-shadow: none;
  //    transition: 0.3s ease;
  //    border: 1px solid $brand-quaternary;
  //
  //    img {
  //      filter: grayscale(50%);
  //    }
  //
  //    &:after {
  //      //opacity: 0;
  //      //width: 100%;
  //      //padding: 5px;
  //      //bottom: -30px;
  //      //position: absolute;
  //      //text-align: center;
  //      //content: 'View/Edit';
  //      //color: $brand-secondary;
  //      //background: $light-gray;
  //      //transition: 0.3s ease-out;
  //      //border-top: 1px solid $brand-quaternary;
  //
  //      display: flex;
  //      width: 100%;
  //      content: 'View/Edit';
  //      justify-content: center;
  //      position: relative;
  //      padding: 5px;
  //      text-align: center;
  //      opacity: 1;
  //      color: $brand-secondary;
  //      background: $light-gray;
  //    }
  //
  //    &:hover {
  //      opacity: 1;
  //      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  //
  //      img {
  //        filter: grayscale(0);
  //      }
  //
  //      &:after {
  //        opacity: 1;
  //        bottom: 0;
  //        box-shadow: $shadow;
  //      }
  //    }
  //  }
  //
  //  .section-open {
  //    cursor: pointer;
  //    box-shadow: none;
  //    //transition: .3s ease;
  //    border: 1px solid $brand-quaternary;
  //
  //    img {
  //      filter: grayscale(50%);
  //    }
  //
  //    &:after {
  //      //opacity: 1;
  //      //width: 100%;
  //      //padding: 5px;
  //      //bottom: 0;
  //      //position: absolute;
  //      //text-align: center;
  //      //content: 'Close';
  //      //color: $brand-secondary;
  //      //background: $light-gray;
  //      //border-top: 1px solid $brand-quaternary;
  //
  //      display: flex;
  //      opacity: 1;
  //      width: 100%;
  //      content: 'Close';
  //      justify-content: center;
  //      position: relative;
  //      padding: 5px;
  //      text-align: center;
  //      color: $brand-secondary;
  //      background: $light-gray;
  //    }
  //  }
  //
  //  .btn-bar {
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //  }
  //
  //  /*********************
  //    INSURER: close info
  //   *********************/
  //
  //  .close-insurer {
  //    bottom: 0;
  //    color: gray;
  //    opacity: 0.8;
  //    width: 100%;
  //    height: 30px;
  //    padding: 5px;
  //    cursor: pointer;
  //    position: absolute;
  //    text-align: center;
  //    transition: 0.3s ease;
  //    background: $light-gray;
  //    border-top: 1px solid $brand-quaternary;
  //
  //    &:hover {
  //      opacity: 1;
  //      color: $brand-secondary;
  //    }
  //  }
  //
  //  /****************************
  //    ANIMATED - PRODUCT-SECTION
  //  *****************************/
  //
  .section-title {
    position: relative;
    //margin-bottom: 15px;
    transition: 0.3s ease;
    box-shadow: none;
    padding: 8px 15px;
    border-radius: $radius-normal $radius-normal 0 0;
    cursor: pointer;

    .join {
      transition: 0.3s ease;


      border-bottom: none;

      h2 {
        color: white;
        transition: 0.3s ease;
      }

    }
  }

  .scheme-management-section-container-section {
    padding: 10px;

    .join {

      transition: 0.3s ease;
      border: 1px solid $brand-primary !important;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    }
  }

  .section-title-default {
    color: $brand-primary;
    background: $brand-quinternary;
    border: 1px solid $brand-quaternary;
  }

  .section-title-active {
    background: $brand-primary;
    color: $brand-quinternary;
  }

}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


