$dark-blue: #0A45DB;
$light-blue: #0DD9EB;
$pink: #FE86C1;

$outline-green: #009227;
$outline-blue: #0DD9EB;
$gold: #bb9b68;

$red: #cc0033;
$blue: #243b79;
$dark-gray: #4f4f4f;
$light-gray: #f2f2f2;
//$light-gray: #F7F7F5;
$blue-gradient: linear-gradient(to top, #0078ff, #00b8ea);
$blue-gradient-hover: linear-gradient(to top, rgba(0,120,255,0.6), rgba(0,184,234,0.7));

$green-gradient: linear-gradient(to top, #00a32e, #00c535); // TODO: remove
$green-gradient-lighter: linear-gradient(rgba(0,163,46,0.6), rgba(0,197,53,0.7)); // TODO: remove

$light-gray-transparent:rgba(250, 250, 250, 0.97);
$black-transparent:rgba(0, 0, 0, 0.2);

$shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
$shadow2: 0px 2px 4px rgba(0, 0, 0, 0.05);
$shadow3: 0px 2px 4px rgba(0, 0, 0, 0.2);
$shadow4: 0px 2px 4px rgba(0, 0, 0, 0.1);
$shadow4-inset: inset 0px 1px 6px rgba(0, 0, 0, 0.1);
//$shadow4-inset: none;
$shadow5: 0px 2px 18px rgba(0, 0, 0, 0.2);

$brand-primary: #0A45DB;
$brand-secondary: #0DD9EB;
$brand-tertiary: #0A45DB; // Checkbox Border old: #2350a0
$brand-white: white;
$brand-quaternary: #BCBCAB;
$brand-quaternary-light: lighten($brand-quaternary, 40%);
$brand-quinternary: #f0f0eb;
$brand-error: #E0413E;
$brand-success: #5CB85C;
//$brand-sexternary: #41a5f5; // (Info icons)
$brand-sexternary: #0DD9EB; // (Info icons)
$banner-background-colour: #0A45DB;


$brand-links-guest: darken(white, 15%);

// Communication box Background
$comm-box-background: #3c82a8;

//Tables
$table-titles: #243b79;
$table-line: #BCBCAB;

//tabs
$tabs-border-bottom: $light-blue;
$tabs-text-color: $dark-blue;

//buttons
$button-primary-border: $light-blue;

//Medical Screening
$background-condition: #f8f8f8;

$font-roboto: "Roboto Slab", serif;
$font-header: Muli, Helvetica, Arial, sans-serif;
$font-body: $font-header;
$font-button: Muli, Helvetica, Arial, sans-serif;
$font-handwriting: 'Cookie', cursive;

//P J Hayman Forms
$internal-title: #bb9b68;
$quotation-type: white;

//Medical Buttons
$medical-checked-button: $brand-secondary;
$medical-unchecked-button: $brand-secondary;
$medical-button-border: $brand-secondary;
//lighten(#feb645, 10%);

//Others Buttons
$checked-button: #243b79;
$unchecked-button: lighten(#243b79, 15%);
$button-border: rgba(140, 116, 78, 0.2);
$button-checked-2: rgba(187, 155, 104, 0.2);
//$unchecked-button2: #b69665;

$no-border: 0px solid #FFF;
$content-background: #FFF;
$minimum-content-height: 35em;
$background-white: white;


$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

// Radius Variables
$radius-small: 2px;
$radius-normal: 4px;
$radius-button: 8px;
$radius-medium: 6px;
$radius-large: 10px;

// Responsive Variables
$screen-xs-min: 480px; // ~Phone Size
$screen-sm-min: 768px; // ~Phablet Size
$screen-md-min: 992px; // ~Tablet Size
$screen-lg-min: 1200px; // ~Desktop Size
$screen-lg-01: 1300px; // ~Projector


//Check Box Enabled Variables
$checkbox-enabled-char: '\26AB'; // Medium Black Circle
$checkbox-enabled-color: lighten(#6d6e70, 23%);
$check-box-enabled-position-top: -18.5px;
$check-box-enabled-position-left: -1px;

$form-background: darken($light-gray, 1%);

// Variable for the color of text of Quotes for Leisure Travel
$leisure-travel-text: lighten($brand-primary, 10%);

// Background for Premium Adjustment Input Fields
$premium-adjust-background: #FDFDFD !important; // lighten(#f2f2f2, 3%)

$error-background: #ffd3d8;
$error-color: #cb0015;

$breadcrumb: $brand-primary;

$error-background: #ffd3d8;
$error-color: #cb0015;
$error-color-border-button: #ffa7a2;
$error-background-react-select: #ffebeb;
@mixin centred{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
$form-control-min-height: 34px;

$status-active: #5CB85C;
$status-pending: #68B9C1;
$status-ended: #D44745;
$status-test: #D4BD55;
$status-inactive: #969092;

