.covid-statement {

  color: #c22116;
  font-size: 0.9em;

  &--header {
    color: #c22116;
    font-size: 1em !important;

  }

  h1, h2, h3, h4, h5, h6 {
    color: #c22116 !important;
  }
}
