@import "../../../styles/config/variables";

.modal-dialog .modal-content {
  border: $no-border;
  border-radius: $radius-normal;
  background-color: transparent;
  .modal-header {
    background-color: $brand-secondary;
    color: #FFF;
    border-top-left-radius: $radius-normal;
    border-top-right-radius: $radius-normal;
    h4, .close {
      color: #FFF;
      opacity: 1;
    }
  }
  .modal-body {
    background-color: $content-background;
  }
  .modal-footer {
    border-top: none;
    background-color: $content-background;
    border-bottom-left-radius: $radius-normal;
    border-bottom-right-radius: $radius-normal;

    .btn {
      font-size: 14px;
    }
  }
}

.settings-button{
  width: 140px;
  height: auto;
  font-size: 14px !important;
  text-align: center;
  margin-bottom: 5px;
}

.modal-footer {
  .button-bar {
    margin-top: 0 !important;
  }
}
