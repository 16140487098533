@import '../../../../styles/config/variables';

.scheme-header {
  display: flex;
  min-height: 50px;
  position: relative;

  .product-title {
    flex: 10;

    h3,
    h2 {
      float: none;
      margin-bottom: 5px;
    }
  }

  .status {
    left: 0;
    top: 3px;
    float: left;
    font-size: 1em;
    padding: 2px 5px;
    position: relative;
  }

  &--dropdowns {
    display: flex;
    flex-direction: column;
  }

  &--version-dropdown {

    flex: 1;
    right: 0;
    padding: 0;
    text-align: right;
    //position: absolute;
    margin-bottom: 15px;

    .btn-default {
      right: 0;
      top: 0 !important;
      margin-bottom: 15px;
      text-transform: lowercase;
      min-width: 30px !important;
      padding: 2px 10px !important;
      background: $brand-secondary;
      border-radius: $radius-normal;

      &.status-active {
        background: rgb(92, 184, 92);

        &:hover {
          background: rgb(92, 184, 92);
        }
      }

      &.status-pending {
        background: rgb(104, 185, 193);

        &:hover {
          background: rgb(104, 185, 193);
        }
      }

      &.status-test {
        background: rgb(212, 189, 85);

        &:hover {
          background: rgb(212, 189, 85);
        }
      }

      &.status-inactive {
        background: rgb(150, 144, 146);
      }

      &.status-ended {
        background: rgb(212, 71, 69);

        &:hover {
          background: rgb(212, 71, 69);
        }
      }
    }

    &--dropdown-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--dropdown-item-title {
      margin-right: 5px;
    }

    .dropdown-item-status {
      //top: 30px;
      //right: 30px;
      color: white;
      padding: 5px 10px;
      //position: absolute;
      font-size: small;
      border-radius: $radius-normal;

      &.active {
        background: rgb(92, 184, 92);
        animation: ItsOn 4s ease infinite;
      }

      &.pending {
        background: rgb(104, 185, 193);
        animation: ItsOn 4s ease infinite;
      }

      &.test {
        background: rgb(212, 189, 85);
      }

      &.inactive {
        background: rgb(150, 144, 146);
      }

      &.ended {
        background: rgb(212, 71, 69);
      }
    }
  }


  &--dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--dropdown-item-title {
    margin-right: 8px;
  }

  .dropdown-item-status {
    color: white;
    padding: 3px 7px;
    font-size: small;
    border-radius: $radius-normal;

    &.active {
      background: rgb(92, 184, 92);
      animation: ItsOn 4s ease infinite;
    }

    &.pending {
      background: rgb(104, 185, 193);
      animation: ItsOn 4s ease infinite;
    }

    &.test {
      background: rgb(212, 189, 85);
    }

    &.inactive {
      background: rgb(150, 144, 146);
    }

    &.ended {
      background: rgb(212, 71, 69);
    }
  }

}
