@import '../../../../styles/config/config';

.superseded-document-list-item-container {

  .superseded-document-content {
    clear: both;
    display: table;
    width: 100%;
    border: 1px solid $brand-quinternary;
    border-radius: $radius-normal;

    margin-bottom: 15px;

    padding: 15px;


    .dropdown {
      float: left;
    }
  }

}
