@import "./config/config";
@import "./layout.scss";
@import "./typography.scss";
@import "./tabs.scss";
@import "./transitions.scss";
@import "./browser.scss";
@import "env_banner.scss";
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab);
@import url(https://fonts.googleapis.com/css?family=Cutive);
@import url(https://fonts.googleapis.com/css?family=Anton);
@import url(https://fonts.googleapis.com/css?family=Cookie);
@import url(https://fonts.googleapis.com/css2?family=Muli:wght@400;700&display=swap);

html, body {
  background: #F7F7F5;
  min-height: 100vh;
  color: #6d6e70;
  background: $light-gray !important;
  font-family: Muli, Helvetica, Arial, sans-serif;
}
//.app-body {
//  display: flex;
//  flex-direction: column;
//}
textarea {
  resize: vertical;
}

.relative {
  position: relative;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.padded-row {
  padding-left: 0;
}

.internal-container {
  margin-bottom: 2em;
}

$body-height: calc(100vh - 100px - 84px);

.body-section {
  position: relative;
  min-height: $body-height;
  // display: flex;
  align-items: stretch;

  width: 100%;
  > div {
    flex: 1;
  }

  .flex-container-body {
    flex: 1;
    display: flex;
    align-items: stretch;
    flex-direction: row-reverse;
    //border: 10px solid orange;

    /*------------
    WIDTH CLASSES
    ----------*/

    .width-5 {
      width: 5%;
      float: left;
      background: white;
      margin: 0;
      position: relative;
      height: 100px;
      transition: 0.5s;
      z-index: 1;

      @media (max-width: $screen-md-min) {
        display: none;
      }
      @media (min-width: 1400px) {
        width: 4%;
      }
      @media (min-width: 1700px) {
        width: 3%;
      }
    }

    .width-20 {
      width: 20%;
      float: left;
      margin: 0;
      background: white;
      position: relative;
      min-height: $body-height;
      transition: 0.5s;
      z-index: 1;

      @media (max-width: $screen-md-min) {
        display: none;
      }
      @media (min-width: 1400px) {
        width: 18%;
      }
      @media (min-width: 1700px) {
        width: 16%;
      }
    }

    .width-80 {
      width: 80%;
      float: right;
      margin: 0;
      position: relative;
      min-height: $body-height;
      transition: 0.5s;

      @media (max-width: $screen-md-min) {
        width: 100%;
      }
      @media (min-width: 1400px) {
        width: 82%;
      }
      @media (min-width: 1700px) {
        width: 84%;
      }

      &:before {
        content: '';
        position: absolute;
        width: 25%;
        float: left;
        background: white;
        margin: 0;
        left: -25%;
        padding: 0;
        height: 100%;
        z-index: 0;
      }
    }

    .width-95 {
      width: 95%;
      float: right;
      margin: 0;
      position: relative;
      min-height: $body-height;
      transition: 0.5s;

      @media (max-width: $screen-md-min) {
        width: 100%;
      }
      @media (min-width: 1400px) {
        width: 96%;
      }
      @media (min-width: 1700px) {
        width: 97%;
      }

      &:before {
        content: '';
        position: absolute;
        width: 25%;
        float: left;
        background: white;
        margin: 0;
        left: -25%;
        padding: 0;
        height: 100% !important;
        z-index: 0;
      }
    }
  }
}

.wrap-internal-page {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
}

.footer-section {
  width: 100%;
  float: left;
  position: relative;
}

/*---------------
  elastic page - hide sidebar function
--------------- */

.elastic-page {
  width: 100%;
  float: right;
}

.collapsed-sidebar {
  position: absolute;
  padding: 15px;
  z-index: 2;
  font-size: 28px;
  background: white;
  height: 100%;
  right: 0;
}

.collapsed-sidebar-hidden {
  position: absolute;
  padding: 15px;
  z-index: 2;
  font-size: 28px;
  right: 0;

}

.collapse-btn {
  cursor: pointer;
  opacity: 0.3;

  &:hover {
    opacity: 0.5;
  }
}

form {
  .form-group {
    margin: 10px -15px;
  }

  .form-group:after {
    clear: both;
    content: ' ';
    display: table;
  }

  .form-group:before {
    content: ' ';
    display: table;
  }

  .control-label {
    margin-top: 10px;
    padding-top: 0 !important; //remove cega padding;
  }

  .input-group {
    .form-control:first-child {
      border: 0 !important;
    }
  }

  .input-group-addon {
    border: 0 solid $brand-secondary !important;
    color: white;
    background-color: $brand-secondary;
  }

  .input-group-addon:last-child {
    border: 0 !important;
  }

  .input-group .form-control:last-child {
  }

  .form-control {
    //line-height: 38px !important;
    min-height: $form-control-min-height !important;
    border-radius: 4px !important;
  }

  .form-horizontal .control-label {
    padding-top: 0 !important; //remove cega padding;
  }

  .form-control.textarea {
    min-height: 100px !important;
  }

  @media (min-width: 768px) {
    .form-horizontal .control-label {
      padding-top: 0 !important; //remove cega padding;
    }
  }
}

.form-control {
  min-height: 38px !important;
}

.form-control-static {
  padding-top: 10px !important;
  font-style: $font-size-base !important;
}
