@import "./variables";

.rc-slider {
  margin: 25px 0 15px 0;
  background: none;
  padding: 0;

  .rc-slider-rail {
    background: $brand-tertiary;
    height: 8px;
    border-radius: 5px;
  }

  .rc-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -7px;
    margin-left: -12px;
    background-color: $brand-secondary;
    border-color: $brand-secondary;
  }

  .slider-handle {
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 40px;
    padding: 4px 2px;
    border: 2px solid $brand-secondary;
    border-radius: 10px;
    background: #fff;
    font-size: 11px;
    text-align: center;
    z-index: 3;
  }

  &.rc-slider-disabled {
    cursor: progress;
    .slider-handle {
      cursor: progress;
    }
  }
}

.rc-slider-track {
  background: $brand-secondary;
  height: 8px;
}

.rc-slider-dot {
  width: 12px;
  height: 12px;
  bottom: -6px;
  display: none;
  border-color: $brand-tertiary;
}

.rc-slider-mark {
  top: 18px;
  .rc-slider-mark-text {
    color: $brand-tertiary;
  }
}

.rc-other {
  margin-top: 30px;
}



