@import "../../../../../../../styles/config/config";

.wl-medical-tab {

  &--heading {
    color: $pink;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 10px;


    &--question-header {
    }
  }
}
