@import "../../../styles/config/variables";

.search-has-error {
  padding-left: 10px;
  color: $error-color;
  width: 80%;
}

.pad-top {
  margin-top: 5px;
}

.search-title {
  color: $brand-secondary;
  padding-top: 10px;
  font-weight: bold;
  font-size: 1.3em;
  span {
    font-size: 0.9em;
    font-weight: normal;
  }
  padding-bottom: 30px;
}

.flex-container-dashboard {
  display: flex;
  justify-content: space-between;
  @media (max-width: $screen-lg-min) { //1200px
    flex-direction: column;
  }
}

.flex-item-01 {
  padding: 5px !important;
}

.flex-item-02 {
  padding: 5px !important;
  .text-dob {
    white-space: nowrap;
    @media (max-width: $screen-xs-min) {
      white-space: normal;
    }
  }

  &.search-button {
    margin-right: 15px;
    @media (max-width: $screen-lg-min) { //1200px
      align-self: center;
      margin-right: -10px;
    }
    @media (max-width: $screen-sm-min) {
      align-self: center;
      margin-right: -58px;
    }
    @media (max-width: $screen-xs-min) {
      align-self: flex-end;
      width: 120%;
    }
  }
}

// Link dashboard
.link-dashboard {
  text-decoration: none !important;
  cursor: default !important;
}

// Input Style
.form-control-quote {
  background: white !important;
  border: 0.8 solid $light-gray !important;
  border-radius: $radius-normal !important;
  //box-shadow: inset 0 0 0 rgba(249, 254, 243, 0.0) !important;
  height:30px;
  //width:250px;
  font-size:12pt;
  &.has-error {
    border: 0.8 solid $brand-error !important;
  }
  @media (max-width: $screen-xs-min) { //480px;
    margin-left: 20px;
  }
}

// Quote for existing User
.quote-container-fields {
  display: inline-block;
  vertical-align: middle;
}

.styles1 {
  color: white;
  background: #8c1b33;
  font-size: 30px;
  margin: 10px 10px 30px;
  padding: 10px;
}

.middlebar {
  font-size: 1em;
  color: $brand-secondary;
  padding: 0;
  height: 120px;
  margin: 2px 2px 20px 2px;


}

.filterCustom {
  border: 0 solid #ffffff !important;
  padding-left: 20px;
  padding-right: 50px;
}


/*-------------
 Select Input
------------*/
.select-box{
  background: white;
  height: 50px;
  top: -100%;
  overflow: hidden !important;
  z-index: 5;
  border-bottom: 1px solid $light-gray;
  display: flex;

  &.dashboard-select {

    .select {
      box-shadow: none !important;
    }
  }

  .select {
    border: none;
    background: none !important;
    background-size: 40px !important;
    padding-bottom: 10px;
    color: $brand-secondary;
    margin: 0 20px;
    font-size: 18px;
    height: 50px;
    z-index: 1;
  }

  .select:active {
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding-bottom: 10px;
    color: $brand-secondary;
    font-size: 18px;
    height: 50px;
  }

  .select2 {
    border: none;
    background: none !important;
    padding-bottom: 10px;
    color: $brand-secondary;
    font-size: 18px;
    height: 50px;
    z-index: 1;
    margin: 0 20px;
  }
}




/* --------------------------------------
   List Group Horizontal 1
   -------------------------------------- */

.list-group-horizontal .list-group-item {
  display: inline-block;
  margin-bottom: 0;
  float: left;
  width: 20%;
  height: 120px;
  border-bottom: none;
  border-top: none;
  text-align: center;
  font-size: 24px;
  color: $brand-secondary;
  overflow: auto;
  border-right: none;
  border-radius: 0;

}

.list-group-item-text {
  padding: 5px;
  text-align: center;
  font-size: 20px;
  color: $brand-secondary;
  h4 {
    font-size: 16px;
  }
}

.list-group-horizontal .list-group-item:first-child {
  padding-right: 0;
  border-left: none;
  background: $brand-secondary;
  h4 {
    color: white;
    text-align: left;
  }
}

.list-group-horizontal .list-group-item:last-child {
  border-right: none;
}

/* --------------------------------------
   List Group Horizontal 2
   -------------------------------------- */

.list-group-horizontal2 .list-group-item {
  display: inline-block;
  margin-bottom: 0;
  float: left;
  width: 100%;
  padding: 0;
  height: 120px;
  border-bottom: none;
  border-top: none;
  text-align: center;
  font-size: 24px;
  color: $dark-gray;
  overflow: auto;
  border-right: none;
  border-radius: 0;

}



.list-group-horizontal2 .list-group-item-text {
  padding: 5px;
  text-align: center;
  font-size: 20px;
  color: $dark-gray;
}

.list-group-horizontal2 .list-group-item:first-child {
  padding-right: 0;
  border-left: none;
}

.list-group-horizontal2 .list-group-item:last-child {
  border-right: none;
}

/* --------------------------------------
   Graphic divs
   -------------------------------------- */
.width-100 {
  width: 100%;
  float: left;
  overflow: auto;
}

.width-50 {
  width: 50%;
  float: left;
  @media (max-width: $screen-lg-min) {
    width: 40%;
  }

}


.conversion-label-box {
  padding: 15px 20px 0 0;
  float: right;
  @media (max-width: $screen-lg-min) {
    padding: 15px 5px 0 0;
  }
}

.divider-border {
  border-bottom: 1px solid $light-gray;
  padding-bottom: 0;
}

.vertical-align {
  margin-top: 30px;
}

/* --------------------------------------
   GET QUOTES BOX
   -------------------------------------- */

.get-quotes-header {
  border-bottom: 1px solid $light-gray;
  background: white;
  padding: 10px 30px;
  text-decoration: none;
  cursor: default;

  .btn {
    @media (max-width: $screen-xs-min) {
      width: 87%;
      margin-right: 20px;
    }
  }

  h3 {
    padding-top: 12px;
    font-weight: bold;
    font-size: 18px;
    color: $brand-secondary;
    margin-bottom: 0;
    @media (max-width: $screen-xs-min) {
      display: none;
    }
  }
}

.in-quote-box {
  @media (min-width: 1600px) {
    width: 90%;
  }
  @media (min-width: 1700px) {
    width: 80%;
  }
  @media (min-width: 1800px) {
    width: 70%;
  }
}

.quotes-box {
  .product-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .info-button-icon {
      margin-right: 10px;
    }
  }
  .quotes-menu-item{
    flex-grow: 2;
    background: $light-gray;
    border-radius: $radius-normal;
    margin: 3px 0;
    padding: 10px 15px;

    &:hover {
      a {
        text-decoration: none !important;
      }
      background: darken($light-gray, 5%);

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 52px;
        background: white;
        opacity: 0;
        top: 0;
        left: -5px;
        z-index: 2;
        cursor: pointer;
      }

      @keyframes slide {
        0% { width: 0; opacity: 0.5;}
        100% { width: 100%; opacity: 0;}
      }
    }

    .icon-img {
      color: $brand-secondary;
      width: 30px;
      height: 30px;
      vertical-align: middle;
      margin-right: 15px;
    }

    .icon {
      color: $brand-secondary;
      font-size: 24px;
      vertical-align: middle;
      margin-right: 30px;
    }

    .icon-right {
      float: right;
      margin-right: -25px;
    }
  }

  .table-hidden {
    visibility: hidden;
  }
}

/* --------------------------------------
   NO QUOTES AVAILABLE BOX
   -------------------------------------- */

.quotes-menu-no-quotes {
  padding: 18px;
  text-align: left;
  font-size: 15px;
  color: grey;
}

/* Hack min height | Dashboard requires redesign */

.dashboard-container {
  .internal-container {
    min-height: 420px;
    .get-quotes-header {
      height: 60px !important;
    }
    .quotes-box {
      //height: 360px;
    }
    .is-searched {
      height: auto;
    }
  }
}
