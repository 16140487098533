.note {
  margin-top: 5px;
  margin-left: 10px;
  width: 100%;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .flex-item-text {
    padding-left: 6px;
  }
}
