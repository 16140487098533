@import "../../../../../../styles/config/config";

/**************************
Issue Button Payment Buttons
**************************/

.radio-container-payment {
  clear: both;

  &.inline {
    display: inline-block;
    margin-right: 15px;
  }

  &--disabled {
    opacity: 0.5;
    cursor: pointer;
  }

  .form-radio {
    input {
      display: none;
    }

    label {
      .radio-label {
        color: white;
        margin-left: -4px;
        float: right;
        transition: all .3s linear;
        padding: 9px 27px;
        cursor: pointer;
        border-radius: $radius-medium;
        border: 0.1px solid $button-border;
        background: $unchecked-button;
        svg {
          margin-right: 5px;
        }
      }
    }

    input:checked + label {
      .radio-label {
        background: $checked-button;
      }
    }
  }
}

.has-error-input {
  .form-control,
  .radio-container .form-radio {
    border-color: $error-color;
    color: $error-color;
    background: $error-background;
    .radio-label {
      color: $error-color;
    }
  }
}
