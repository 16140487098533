@import './config/config';

.nav-tabs {
  border-bottom: $no-border !important;
  margin-bottom: -5px !important;
  width: 100%;
  display: inline-block;
  background-color: white;
  border-top-left-radius: $radius-normal;
  border-top-right-radius: $radius-normal;
  border-bottom: 1px solid $light-gray;
  li {
    padding: 5px;
    width: 20%;
    border: 1px solid transparent;
    @media(max-width: $screen-md-min) {
      width: 49%;
    }
    text-align: center;

    overflow: hidden;
    a {
      outline: none !important;
      border: none !important;
      border-top-left-radius: $radius-normal;
      border-top-right-radius: $radius-normal;
      font-size: 1em;
      background-color: transparent;
      color: $dark-gray;
      padding-left: 25px !important;
      padding-right: 25px !important;
      margin-right: 1px !important;
      &:hover {
        opacity: .7;
        background-color: $brand-primary;
        position: relative;
      }
    }

    &.tabs-6 {
      width: 16.666% !important;
    }

    &.tabs-7 {
      width: 14.285% !important;
    }

    &.tabs-8 {
      width: 12.5% !important;
    }

    &.tabs-9 {
      width: 11.111% !important;
    }

    &.tabs-10 {
      width: 10% !important;
    }
  }

  li.active {
    border-bottom: 2px solid $brand-secondary;
    position: relative;
    a {
      border: none;
      padding-bottom: 9px;
      background: white !important;
      color: $brand-secondary !important;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.tab-content {
  //background-color: $content-background;
  min-height: $minimum-content-height;
  border-bottom-left-radius: $radius-normal;
  border-bottom-right-radius: $radius-normal;
  border-top-right-radius: $radius-normal;
  //padding: 1.5em;
  border-top: 1px solid $light-gray;

  .inner-content {
    min-height: $minimum-content-height - 5em;
  }

}

.dropdown-menu {
  border-radius: 0;
}

.bottom-top{
  border-top: 1px solid $light-gray;
}
