html, body {
  font-family: $font-body;
  font-weight: $font-weight-regular;
  font-size: 72.5%;
  line-height: 1.1em
}

p {
  font-family: $font-body;
  font-weight: $font-weight-regular;
  font-size: 1.2em;
  line-height: 1.3em
}

div h1,
div h2,
div h3,
div h4,
div h5,
div h6 {
  color: $brand-primary;
  margin-top: 0px;
  margin-bottom: 0.8em;
  font-family: $font-header;
  font-weight: $font-weight-light;
  line-height: 1.1em;
}

div h1 {
  font-size: 3em;
}

div h2 {
  font-size: 1.7em;
}

div h3 {
  font-size: 1.5em;
  color: $pink;
}

.note {
  color: $brand-tertiary;
  font-size: 0.9em;
}

.button-font {
  font-family: $font-button;
  font-size: 1.2em;
}

.mission-accomplished {
  font-family: $font-handwriting;
  color: $brand-secondary;
  font-size: 4em;
}

.bold-heading {
  font-weight: 400;
}

.btn {
  outline: none !important;

}

.resource-name {
  font-weight: 400;
  border: 1px solid transparent;
  min-height: 60px;
}

.input-error {
  color: #c22116;
  line-height: 2em;
  font-size: 12px;
  padding-left: 2px;
}

.title-case {
  text-transform: capitalize;
}

.capitalise::first-letter {
  text-transform: capitalize;
}
