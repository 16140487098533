@import '../../../styles/config/variables';

.loading__container {
  position: relative;
  width: 100%;
  min-height: 500px;
}

.loading__center-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.loading__circle {
  width: 10rem;
  height: 10rem;
  border: 4px solid;
  border-color: $brand-secondary;
  border-top-color: transparent;
  border-radius: 100%;
  display: block;
  animation: rotate 1.5s linear infinite;
}

.loading__logo {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  background: $brand-primary url('../../../images/brand/pjh_logo.svg') center no-repeat;
}

.loading__message {
  font-size: 1.2em;
  padding: 30px;

  .loader-dots {
    font-size: 14px;
    margin-top: 30px;
  }
  text-align: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.page-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: .85;
  display: flex;
  align-items: center;
}
