@import '../../../../styles/config/config';

.document-list-item-container {

  border: 1px solid $brand-quaternary;
  margin-bottom: 20px;
  display: flex;

  .document-content {
    width: 100%;
    padding: 15px;
    margin-bottom: 5px;

    .document-insurer-logo {
      margin-top: 5px;

      img {
        max-height: 45px;
      }
    }

    .document-content-header {
      height: 50px;

      &:hover {
        cursor: pointer;
      }

      .refresh {
        margin-left: 5px;
      }
    }
  }

  .superseded-documents {
    padding-top: 10px;
    clear: both;
    display: table;
    width: 100%;
    min-height: 95px;

    .superseded-document-content {
      padding: 0;
      border: 1px solid green;
      clear: both;
      display: table;
      width: 100%;

      .dropdown {
        float: left;
      }
    }

    .show-documents {
      background: lighten($brand-quinternary, 10%);
      border: 1px solid $brand-secondary;
      color: darken($brand-secondary, 20%);
      padding: 15px;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
      }
    }

    .no-documents-alert {
      background: lighten($brand-quinternary, 10%);
      border: 1px solid $brand-quinternary;
      color: darken($brand-quinternary, 20%);
      padding: 15px;
      border-radius: 4px;
    }
  }

  .pad-form-row {
    margin: 10px 0;
  }

  .doc-warning {
    padding: 10px;
  }

}
