@import "./variables";
.rangeslider {
  &.rangeslider-horizontal  {
    background: $brand-tertiary;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.4);
    height: 10px;

    .rangeslider__fill {
      background-color: $brand-secondary;
    }

    &.rangeslider-reverse {
      background: $brand-secondary;
      .rangeslider__fill {
        background-color: $brand-tertiary;
      }
    }

    .rangeslider__tooltip {
      width: auto;
      min-width: 50px;
      margin-left: -8px;
      top: -50px;

      span {
        margin: 0;
        line-height: 0;
      }

      > span {
        display: block;
        width: auto;
        padding:0 5px 5px 5px;
        text-wrap: none;
        white-space:nowrap;
        overflow: hidden;
        line-height: 2.8em;
      }
    }

    .rangeslider__handle {
      width: 25px;
      height: 25px;
      background: $brand-secondary;
      border: none;
      cursor: pointer;
      display: inline-block;
      position: absolute;
      top: 4px; // Fixed
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
      z-index: 10;

      &:after {
        content: '';
        display: none;
      }
    }
  }
}
