@import "../../../../styles/config/config";

.header-publics {
  //margin-bottom: 100px;
  border-radius: 0;
  border-bottom: 0;
  box-shadow: $shadow;
  //background: url(../../../../images/brand/header.png) center no-repeat;
  //background: #FFF !important;
  background: $banner-background-colour;
  width: 100%;
  height: 100px;
  position: relative;
  top:0 !important;
  z-index: 2;
  @media (max-width: $screen-sm-min) {

  }
}

.logo {
  color: #00a32e;
  width: 330px;
  float: left;
  margin: 10px;
  @media (max-width: $screen-sm-min) {
    width: 250px;
    margin: 25px 10px;
  }
}

.guest-title {
  background: $brand-secondary;
  padding: 20px 0 20px 40px;
  box-shadow: $shadow;
  width: 100%;
  color: white;
  font-size: 24px;
  font-weight: bold;
  z-index: 0;
  @media (max-width: $screen-sm-min) {
    font-size: 16px;
    padding: 20px 0 20px 15px;
  }
}


.guest-nav {
  position: absolute;
//  //width: 400px;
  right: 0;
  top: 25px;
  display: flex;
//
  @media (max-width: $screen-lg-min) {
    top: 10px;
    width: 460px;
  }

  //.nav-dropdown {
  //  li {
  //    float: none;
  //  }
  //}


  li {
    float: left;
    display: block;
    width: auto;
    font-size: 13px;
    @media (max-width: $screen-sm-min) {
      font-size: 14px !important;
    }
  }

  //li:first-child:after {
  //  content: "";
  //  top: 10px !important;
  //  position: absolute;
  //}
  li:after {
    content: "|";
    top: 0;
    position: absolute;
    color: white;
    padding: 10px 15px;
  }

  .last:after {
    content: ""
  }


  //@media (max-width: $screen-lg-min) {
  //  li:nth-child(5):after {
  //    content: "";
  //    top: 10px !important;
  //    position: absolute;
  //    left: 35px;
  //    color: white;
  //  }
  //
  //}

  li {
    color: $brand-white;
    padding: 10px 15px;
    a {
      color: $brand-white;

    }
  }

  li:active li:hover {
    color: $brand-secondary !important;
  }
  //
  //a {
  //  color: $brand-white;
  //
  //}

  //a:focus {
  //  color: $brand-links-guest !important;
  //}
  //
  //a:hover {
  //  color: $brand-links-guest !important;
  //}
  //
  //.guest-nav--white {
  //  a {
  //    color: $brand-white !important;
  //  }
  //}
}


// contact info

.contact-info-page {

  width: 100%;
  margin: 20px;
}

.contact-info-box {

  .head {
    padding: 30px 30px 0;
  }

  width: 45%;
  min-height: 300px;
  float: left;
  background: $background-white;
  margin: 0 15px;

  h4 {
    color: $brand-secondary !important;
    // font-weight: 500 !important;
    // font-size: 28px;
  }

  @media (max-width: $screen-md-min) {
    width: 90%;
    margin: 20px 10px;

  }
}

.box-body {
  padding: 30px;
  p {
    font-size: 15px;
    margin: 0;
  }
  h4 {
    margin-bottom: 5px;
  }

  // font-size: 16px;
}

.info-lines {
  font-size: 22px;
  margin-bottom: 30px;
  font-weight: 200;
}

.colored {
  color: $brand-secondary;
  font-weight: 500;
}
