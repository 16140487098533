@import "../../../../styles/config/config";


.policy-accounts-list{

  &--title-select{
    padding-bottom: 15px;
  }
  &--centred-column{
    @include centred;

  }

  .react-bs-table-expand-cell:not([data-is-only-head]){
    vertical-align: middle;
    text-align: center;

  }

  h3{
    padding-top: 10px;
  }
  .react-bs-container-body {
    min-height: 10px;
  }
}
