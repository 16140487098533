@import "../../../../../styles/config/config";

/**************************
Medical Declaration Buttons
**************************/


.medical-has-error {
  color: #cb0015;
}

.radio-container-medical {
  clear: both;
  &.inline {
    display: inline-block;
  }

  // 'YES' - Button
  &.inline:first-child {
    display: inline-block;
    margin-right: 15px;
  }

  // 'NO' - Button
  &.inline:last-child {
    display: inline-block;
    margin-right: 5px;
  }

  &--disabled {
    opacity: 0.5;
    cursor: pointer;
  }

  .form-radio {
    input {
      display: none;
    }

    label {
      .radio-label {
        //color: ;
        margin-left: -4px;
        float: right;
        transition: all .3s linear;
        padding: 6px 22px;
        cursor: pointer;
        border-radius: $radius-button;
        border: 1px solid $brand-primary;

        background: white; // button unchecked.
        svg {
          margin-right: 5px;
        }
      }
    }

    input:checked + label {
      .radio-label {
        background: $medical-checked-button; // button checked
        color: white;
        border: 1px solid $medical-button-border;
      }
    }
  }
}

/*
.has-error-input {
  .form-control,
  .radio-container .form-radio {
    border-color: $error-color;
    color: $error-color;
    background: $error-background;
    .radio-label {
      color: $error-color;
    }
  }
}*/
