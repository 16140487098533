@import "../../styles/config/config";


/*-------------------
COOKIES & PRIVACY
 ------------------*/
.page-content {
  text-align: justify;
  top: -40px !important;
  position: absolute;

  h1, h2 {
    color: $brand-secondary;
    padding-top: 20px;
  }

  p {
    font-size: 16px;
    //margin-bottom: 10px !important;
  }
}

.remind-pass {
  position: relative;
  top: 30px;
}

.login-margin-top{
  width: 100%;
  padding: 15vh 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-details {
  .contact-item {
    font-size: 1.1em;
    margin-top: 5px;

    @media(min-width: 768px) {
      margin-top: 35px;
      font-size: 1.5em;
    }
  }
  .row {
    margin-bottom: 30px;
    &.details-section {
      margin-top: 60px;
    }
  }

  .social-link {
    margin-right: 20px;
    img {
      max-width: 60px;
    }
  }
}

.btn.btn-white {
  background-color: #FFF;
  border-color: #FFF;
  color: $brand-secondary;
}

.background-red {
  background: $brand-secondary;
  color: #FFF;

  h2, h3, h4 {
    font-weight: $font-weight-light;
    color: #FFF;
  }

  h2 {
    font-size: 1.8em;
    font-family: $font-header;
  }

  h4 {
    font-family: $font-header;
    font-size: 1.5em;
  }

  .btn {
    background-color: #FFF;
    border-color: #FFF;
    color: $brand-secondary;
  }

  .mission-accomplished {
    color: #FFF;
  }

  .large-number {
    background-color: #FFF;
    color: $brand-secondary;
    font-family: $font-button;
    font-size: 3em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    width: 1.5em;
    height: 1.5em;
    border-radius: 1em;
  }
}

.full-width-section {
  padding: 40px 0;
  &.password-reminder {
    padding: 100px;
  }
  background-size: cover;
  position: relative;


  // TODO: Clean this up and make it more generalised
  .divider-above {
    width: 100vw;
    position: relative;
    margin-top: -280px;
    height: 190px;
    overflow: hidden;
    margin-bottom: 60px;
  }

  .divider-above:after {
    content: " ";
    position: absolute;
    left: -10vw;
    width: 120vw;
    height: 99%;
    overflow: hidden;
    border-radius: 0 0 100% 100%;
    box-shadow: 0 0 0 100vw $brand-secondary;
  }

  .divider-below {
    width: 100vw;
    position: relative;
    height: 190px;
    border-radius: 100% 100% 0 0;
    overflow: hidden;
    z-index: 1;
    margin-bottom: -230px;
    transform: rotate(180deg);
  }

  .divider-below:after {
    content: " ";
    position: absolute;
    bottom: 50px;
    left: -10vw;
    width: 120vw;
    height: 99%;
    overflow: hidden;
    border-radius: 0 0 100% 100%;
    box-shadow: 0 0 0 100vh $brand-secondary;
  }

  .content-box, .tab-content {
    background-color: $brand-quinternary;
    color: $brand-tertiary;
    h1, h2, h3, h4, h5, h6 {
      color: $brand-tertiary;
    }
  }
}

.faded {
  opacity: .4;
}

.secondary {
  .btn-primary {
    background-color: $brand-primary;
  }
}

.partners {
  position: relative;

  > div {
    margin-top: 45px;
    margin-bottom: 25px;
  }

  > .col-md-6 {
    display: flex;
    position: relative;


    > div {
      margin: auto;
      position: absolute;
      bottom: 0;
    }

    .logo-wrapper {
      position: relative;
      margin-bottom: 10px;
      flex-grow: 1;
    }
  }

  .partner-logo {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

.product-details-page {
  h1, h3 {
    text-align: center;
  }

  .product-benefits {
    text-align: center;
  }
}
