@import '../../../../styles/config/config';

.bt {
  border-top: 1px dashed lightgray;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.my-15 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.justify-text {
  text-align: justify;
  text-justify: inter-word;
  resize: vertical;
}

.dynamic-document-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
}
