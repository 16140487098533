@import "../../../../styles/config/config";

.signpost-list-item {
  margin-bottom: 10px;

  &--title {
    font-size: 1.7em;
    color: #6d6e70;
    margin-top: 0;
    margin-bottom: 0.8em;
    font-family: Muli, Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.1em;


  }

  &--content {
    background-color: $background-condition;
    margin-top: 10px;
    clear: both;
    padding: 10px;
    border: 1px solid grey;
  }

}
