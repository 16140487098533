@import '../../../../styles/config/variables';


.scheme-container {

  transition: 0.3s ease;
  border: 1px solid white;

  &:hover {
    z-index: 2;
    color: $blue;
    border: 1px solid $brand-secondary;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);

    .dropdown-menu {
      opacity: 1;
      transition: 0.4s ease;
    }

    h3 {
      color: $blue;
    }
  }

  h2 {
    float: left;
  }

  .hr {
    margin: 15px auto 30px auto;
  }
}
