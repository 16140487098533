.sidebars{
  padding-top: 15px;
  padding-bottom: 30px;
  overflow: hidden;
  background: white;
  height: 100%;
  z-index: 2;
}


.background-area{
  width: 800% !important;
  border: 1px solid red;
  top: 100%;
  vertical-align: bottom;
  background: none;
  position: absolute;
  opacity: 0.8;

}
.footer-box{
  width: 60%;
  border: 1px solid green;
  position: relative;
  opacity: 0.5;
}