@import "../../../../styles/config/config";

.footer.internal {
  background: $brand-primary;
  color: #FFF;
  padding: $full-pad 0;
  min-height: 100px;
  width: 100%;
  position: absolute;
  // z-index: 1; // Affecting datepicker tab01

  .footer-content {
    color: white;
    position: relative;

    p {
      margin: auto;
      text-align: center;
      max-width: 1200px;
      font-weight: 200 !important;
      font-size: 14px;
      color: $light-gray;
    }
  }
}
