@import "../../../../styles/config/variables";

.stats-section-table {
  background-color: white;
  border-radius: 5px;

  h4 {
    padding: 10px 0 0 0px;
    font-weight: bold;
  }
}

.table-dashboard {
  width: 100%;
  .caption {
    font-weight: bold;
  }

  tbody {
    td {
      padding: 10px 0;
      border-top: 1px solid $light-gray;

      &:last-child {
        text-align: right;
      }
    }

    tr:nth-of-type(even) {
      border-top: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
    }
  }
}

.conversion-label {
  background-color: $brand-primary;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  display: inline;
  margin: 5px;

  span {
    font-size: 1em;
  }
}

.login-stats {
  div {
    line-height: 2.7em;
  }

  .border-right {
    border-right: 1px solid #fff;
  }

  h4 {
    color: #fff;
    margin: 0;
  }

  .total {
    font-weight: bold;
    font-size: 2.5em;
    display: block;
    margin: 0 0 -10px 0;
  }
  svg {
    max-width: 80px;
    float: left;
    fill: $brand-secondary;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-right: 10px;
  }
}

.overdue-accounts {
  min-height: 32.8em;
}

/* --------------------------------------
   List of Quotes / Policy / Renewals
   -------------------------------------- */

.listStats {
  color: grey;
  text-align: left;
  font-size: 1em;
  padding: 10px;
  list-style: none;
}
