@import "../../../../styles/config/config";

.footer.guest {
  background: $brand-primary;
  //box-shadow: 0 500px 0 500px $dark-gray;
  color: #FFF;
  //padding: $full-pad 0;
  bottom: 0;


   .footer-content {
    // padding-bottom: $full-pad;
     p{
       margin-top: 20px;
       text-align: center;
       font-weight: 200 !important;
       font-size: 14px;
       color: $light-gray;
     }

   }
 }

.scroll-to-top {
  position: relative;
  bottom: 40px;
  right: 30px;
  cursor: pointer;
  transitionDuration: 0.2s;
  transitionTimingFunction: linear;
  transitionDelay: 0s;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 15px;
  border-radius: 50%;
  display: block;
  &:hover {
    background: rgba(0, 0, 0, 1);
  }
}
