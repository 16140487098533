@import '../../../../styles/config/config';

.wl-content-collapsed-box {
  border: 1px solid $select-input-border-color;
  padding: 0;
  margin-bottom: 15px;
  border-radius: $radius-normal;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s ease;
  color: #6d6e70;

  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }

  .wl-toggle-content-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    transition: 0.3s ease;

    &.active {
      border-bottom: 1px solid $select-input-border-color;
    }

    h4 {
      margin: 0;
      color: #6d6e70;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.02);
    }
  }

  .wl-content-box-content {
    padding: 15px;
    &.disabled {
      opacity: 0.5;
    }
  }
}
