.view-document-modal {


  padding: 20px;


  .document-modal-row {
    margin-bottom: 10px;

    ul {
      padding: 0 0;
    }
  }
}
