@import './config/config';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/v4-shims';
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~bootstrap-sass/assets/stylesheets/_bootstrap.scss';
@import '../../node_modules/react-datepicker/dist/react-datepicker.css';
@import '../../node_modules/draft-js/dist/Draft.css';
@import '../../node_modules/react-redux-toastr/lib/css/react-redux-toastr.min.css';
@import '../../node_modules/react-rangeslider/lib/index.css';
@import '../../node_modules/react-checkbox-tree/src/scss/react-checkbox-tree.scss';

