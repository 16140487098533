@import "../../../styles/config/variables";

.dashboard {
  padding: 10px;
  background: #243b79;
  color: white;
  margin-bottom: 15px;
}

.dashboard__card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding-top: 2px;
  transition: all .3s;
  //border: orange 1px solid;
  border-right: white 1px solid;
  height: 60px;
  &--no-border {
    border: 0;
  }
  &--cursor-off {
    cursor: not-allowed;
  }
  &--item {
    padding-top: 1px;
    font-size: 2.5rem;
  }
  @media (max-width: 990px) {
    border-right: 0;
  }
}

.dashboard__card--hover-effect {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &:hover {
    opacity: 0.6;
    filter: alpha(opacity=50);
  }
}

.printjob__stats-refresh {
  position: absolute;
  right: 30px;
  top: -33px;
  min-width: 0 !important;
  padding: 0 !important;
  width: auto !important;
}
