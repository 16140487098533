@import "../../../../../../styles/config/variables";

.product-name {
  display: flex;
  flex-direction: column;
  //border-bottom: 1px $brand-primary solid;
  //margin-bottom: 10px;
  &--header {
    display: flex;
    align-items: center;
  }

  &--info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;


  }

  &--title {
    font-size: 2.5rem;
    font-weight: 300;
    margin-right: 10px;
  }


  &--status {
    padding: 5px;
    font-size: larger;
  }

  img {
    height: 40px;
  }
}
