@import '../../../../styles/config/config';

.document-list-by-type {
  border: 1px solid $brand-quaternary;
  background-color: $background-white;
  border-radius: $radius-normal;
  margin-bottom: 15px;
  display: flex;
  position: relative;
  overflow: hidden;
  transition: 0.3s ease;
  min-height: 120px;

  &.section-collapsed {
    transition: 0.3s ease;
    cursor: pointer;
    opacity: 0.8;
    border: 1px solid $brand-quaternary;

    &:after {
      transition: 0.3s ease-out;
      content: 'View/Edit';
      position: absolute;
      padding: 5px;
      bottom: -30px;
      width: 100%;
      text-align: center;
      background: $light-gray;
      border-top: 1px solid $brand-quaternary;
      color: $brand-secondary;
      opacity: 0;
    }

    &:hover {
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
      opacity: 1;

      &:after {
        opacity: 1;
        box-shadow: $shadow;
        bottom: 0;
      }
    }
  }

  &.overflow-visible {
    overflow: visible !important;
  }

  &:hover {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);

    h4 {
      transition: 0.3s ease;
      color: $brand-tertiary;
    }

    strong {
      color: $brand-sexternary;
    }
  }

  .document-content {
    width: 100%;
    padding: 15px;
    margin-bottom: 5px;

    .document-insurer-logo {
      margin-top: 5px;

      img {
        max-height: 45px;
      }
    }

    .document-content-header {
      height: 50px;

      &:hover {
        cursor: pointer;
      }

      .refresh {
        margin-left: 5px;
      }
    }
  }


  .close-document-container {
    border-top: 1px solid $brand-quaternary;
    width: 100%;
    height: 30px;
    padding: 5px;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    background: $light-gray;
    color: gray;
    text-align: center;
    opacity: 0.8;
    transition: 0.3s ease;
    z-index: 0;

    &:hover {
      color: $brand-secondary;
      opacity: 1;
    }
  }
}

.document-container-section {
  //border: 1px solid $brand-quaternary;
  //border-radius: $radius-normal;
  //margin-bottom: 15px;
  //display: flex;
  //position: relative;
  //overflow: hidden;
  //transition: 0.3s ease;
  //min-height: 150px;


  //.document-content {
  //  width: 100%;
  //  padding: 15px;
  //  margin-bottom: 5px;
  //
  //  .document-insurer-logo {
  //    //border: 1px solid orange;
  //    // max-width: 100px;
  //    margin-top: 5px;
  //
  //    img {
  //      //width: 100%;
  //      max-height: 45px;
  //    }
  //  }
  //
  //  .document-content-header {
  //    height: 50px;
  //    &:hover {
  //      cursor: pointer;
  //    }
  //  }
  //}


  //.dropdown {
  //  float: right;
  //  clear: both;
  //}
  //
  //.version-dropdown-btn {
  //  padding: 5px;
  //  //width: 20px !important;
  //  min-width: auto !important;
  //  float: right;
  //
  //  &.active {
  //    background: #5cb85c;
  //
  //    &:focus {
  //      background: #5cb85c;
  //    }
  //  }
  //
  //  &.pending {
  //    background: rgb(104, 185, 193);
  //
  //    &:focus {
  //      background: rgb(104, 185, 193);
  //    }
  //  }
  //
  //  &.ended {
  //    background: rgb(212, 71, 69);
  //
  //    &:focus {
  //      background: rgb(212, 71, 69);
  //    }
  //  }
  //}
}
