@import '../../../../styles/config/config';

.product-info-container {
  box-shadow: $shadow4;
  margin-top: 20px;


  .section-title {
    position: relative;
    color: white;
    background: $brand-primary;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: $radius-normal;
    box-shadow: $shadow4;

    &.gray {
      background: $light-gray !important;
      color: $brand-primary;
      padding: 8px 15px;

      .color-box {
        width: 15px;
        height: 15px;
        top: 10px !important;
      }
    }

    &.join {
      background: $brand-quinternary !important;
      color: $brand-primary;
      padding: 8px 15px;
      margin-bottom: 0 !important;
      border: 1px solid $brand-quaternary !important;
      border-radius: $radius-normal $radius-normal 0 0;
      box-shadow: none;

      .color-box {
        width: 15px;
        height: 15px;
        top: 10px !important;
      }
    }

    &.join-cover {
      background: $brand-primary !important;
      color: white;
      padding: 15px;
      margin-bottom: 0 !important;
      border: 1px solid $brand-primary !important;
      border-radius: $radius-normal $radius-normal 0 0;
      box-shadow: $shadow4;

      .color-box {
        width: 15px;
        height: 15px;
        top: 10px !important;
      }
    }

    .btn-toolbar {
      float: right;
      margin-top: -8px;
      margin-right: -8px;
      background: transparent !important;

      .btn-default {
        border: 1px solid white;
        color: white;
        padding: 5px 10px;
        font-size: 12px !important;
        text-decoration: none;
        background: transparent !important;
        box-shadow: $shadow4-inset, $shadow4 !important;

        &:active, &:focus {
          background: rgba(0, 0, 0, 0.5) !important;
        }
      }

      .dropdown-menu {
        overflow: hidden !important;
      }
    }


  }

  .scroll-section {
    max-height: 440px;
    overflow: auto;
    padding: 5px 16px;
  }
}
