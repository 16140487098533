@import "../../../../styles/config/variables";

$border-marquee: 1px solid $outline-blue;

.marquee-box {
  background: rgba(255, 255, 255, 0.4);
  background-clip: padding-box;
  border-top: $border-marquee;
  border-bottom: $border-marquee;
  opacity: 0.8;
  margin-bottom: 15px;

}

.marquee--upper {
  text-transform: uppercase;
  font-weight: bold;
}

.marquee-ul {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 5px;
}

.marquee-li {
  display: inline-block;
  color: $pink;
  margin: 0 35px 0 35px;
  padding: 0;
  font-size: 0.8em;

  span {
    font-family: $font-header;
    font-weight: bolder !important;
    font-size: 1.2em !important;
  }
}

.marquee--link {


}

