@import "../../../styles/config/variables";

.icon{
  margin-right: 5px;
}

.static-icon {
  svg {
    fill: $brand-secondary !important;
  }
}

.task {
  font-size: 1.4em;
  position: relative;
  top: 3px;
  margin-right: 5px;

  &.completed {
    color: $brand-success;
  }
}
