@mixin modal {
  position: relative;
  z-index: 99;

  &__Body--open {
    overflow: hidden;
  }

  &__content {
    height: 100vh;
    outline: none;
    transform: translateY(-10vh);

    > * {
      margin-top: 10vh;
      margin-bottom: 10vh;
    }

    &--after-open {
      transform: translateY(0);
      transition: all 150ms ease-in-out;
    }

    &--before-close {
      transform: translateY(-10vh);
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    overflow-scrolling: touch;

    &--after-open {
      opacity: 1;
      transition: opacity 150ms;
    }

    &--before-close {
      opacity: 0;
    }
  }
}

.ReactModal {
  @include modal();
}
