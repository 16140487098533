@import "../../../../styles/config/config";

.wl-tab-content {

  background: $background-white;
  position: relative;
  height: auto;
  overflow: visible;
  padding: 30px 40px;


  //h4 {
  //  color: $brand-secondary;
  //}


  .questions {
    display: flex;
    flex-direction: column;

  }

}
