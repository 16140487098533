.whitelabel-preview {
  .wl-thumbnail-container {
    margin: auto;
    width: 302px;

    .wl-thumbnail {
      border: 1px solid gray;
      height: 430px;
      position: relative;
    }

    .wl-header {
      flex-shrink: 0;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wl-logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        height: 30px;


        img {
          height: 100%;
          width: auto;
        }
      }
      .wl-headings {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: end;
        height: 100%;

        .links {
          font-size: 6px;
        }

      }
      .wl-info {
        display: flex;
        flex-direction: column;
        .telephone {
          font-size: 8px;
        }
        .hours {
          font-size: 4px;
        }
      }
      .wl-right-hand-container{
        height: 100%;
        margin: 3px;

      }
    }

    .wl-campaign-banner {
      color: #fff;
      background: #bf3838;
      height: 30px;
      width: 100%;
      font-size: 5px;
      padding: 4px;
      span {
        font-size: 4px;
      }
    }

    .wl-breadcrumb {

      width: 100%;
      height: 24px;
      padding: 3px 6px;
      &--page-number {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        font-size: 5px;
        margin-right: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--title {
        font-size: 5px;

      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;
        > li {

          float: left;
          padding: 0;
          font-size: 6px;
          display: flex;
          justify-content: center;
          align-items: center;

          position: relative;



        }
      }
    }

    .wl-quote-container {
      width: 100%;
      height: 240px;
      padding: 10px;
      font-size: 9px;
      hr {
        margin: 2px;
      }

      &--section {
        display: flex;
        justify-content: space-between;
      }

      .wl-quote-header {

        margin: 0;
      }





      .wl-button-bar {
        font-size: 7px;
        padding: 10px 0;
        .wl-button {
          padding: 4px 7px;
          text-align: center;
          border: 0.5px solid;
          cursor: pointer;
        }
      }
    }

    .wl-footer {
      padding: 5px;
      font-size: 5px;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    .premiums {
      width: 75%;
      font-size: 6px;
      table {
        width: 100%;
        td{
          width: 33%;

        }
      }


      &--header{

        padding: 2px;
        font-size: 8px;
        text-align: center;
        border-left: 2px solid white;

      }
      &--price{
        text-align: center;
        font-size: 8px;
      }
      &--select{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 3px;
      }
      &--select-button {

        width:80%;
        text-align: center;
        border-radius: 2px;
      }
      &--label{


      }

      &--btn{
        width: 90%;
        margin-bottom: 2px;
        text-align: center;
        border-radius: 2px;

      }
      &--btns{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 3px;
      }
      &--benefit {
        border-left: 2px solid white !important;
        padding: 5px;
        text-align: center;
        border-bottom: 1px solid;



      }
    }
    .sidebar {
      width: 25%;

      margin-left: 5px;
      &--summary-section {
        padding: 2px;
        font-size: 6px;
        border-bottom:  1px solid;
        display: flex;
        flex-direction: column;
        margin-bottom: 2px;
      }
      &--summary-value {
        font-weight: bold;
      }
      &--info {
        font-size: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2px;
        padding-bottom: 10px;

        &-title{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px;

        }
        &-btn {
          width: 90%;
          margin: 2px;
          padding: 2px;
          text-align:center;
          border-radius: 2px;
        }
      }

      &--contact {
        font-size: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-phone-icon {
          font-size: 16px;
          padding: 1px;
        }
        &-phone {
          font-size: 7px;
          font-weight: bold;
        }
        &-opening {
          font-size: 5px;
          text-align: center;
        }


      }
    }
  }
}
