@import "../../../../../../styles/config/config";

.medical-question-row {
  display: flex;
  flex-direction: column;
  padding: 10px;

  &:nth-child(odd) {
    background: #f8f8f8;
  }

  //&--header{
  //  font-weight: bold !important;
  //  background: none;
  //}
  &--content-heading {

    font-weight: bold !important;
    font-size: inherit;

    background: none;
    margin-top: 10px;
    margin-bottom: 0;
  }

  &--content {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bold;

  }

  &--question {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--content-text {
    font-weight: bold;
    font-size: 1.3rem;
    margin-right: 20px;

  }

  &--header-heading {
    font-weight: bold;
    background: none;
    margin-top: 10px;
    color: $pink;
  }

  &--header-text {

    font-size: 1.3rem;

  }

  &--buttons {
    display: inline-block;
    white-space: nowrap; //nowrap buttons
    object-fit: cover;
    border-radius: 1px;
  }

}
